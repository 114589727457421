import SectionMessage from "@atlaskit/section-message";
import TextField from "@atlaskit/textfield";
import axios from "axios";
import moment from "moment";
import { Toast } from "primereact/toast";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButton from "../../AtlaskitControls/AtkButton";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import PfdIcon from "../../assets/images/file.png";
import PreviewIcon from "../../assets/images/paper.png";
import RemoveIcon from "../../assets/images/trashcan.png";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import CommonValues from "../../common/utils";
import SmartScanMiddleHeader from "./SmartScanMiddleHeader";
import SmartScanSidebar from "./SmartScanSidebar";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dialog } from "primereact/dialog";

export default function SmartScanLandingPage(props: any) {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [documentType, setDocumentType] = useState("");
  const [uploadFile, setUploadFile] = useState<any>([]);
  const [uploadFileLimit, setUploadFileLimit] = useState(1);
  const [filesCount, setFilesCount] = useState(0);
  const [requestFileCount, setRequestFileCount] = useState("");
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [uploadingRetry, setUploadingRetry] = useState(false);
  const [incompleteRequestData, setIncompleteRequestData] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isfileuploaded, setIsfileuploaded] = useState(false);
  const [fileNameTitle, setFileNameTitle] = useState("");
  const [fileName, setFileName] = useState("");
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [uploadPdfUrl, setUploadPdfUrl] = useState("");
  const [errorMessageDocumentType, setErrorMessageDocumentType] = useState("");
  const [showHideCancel, setShowHideCancel] = useState(false);
  const [activeTab, setActiveTab] = useState("Insured");
  const [allCarrierNames, setallCarrierNames] = useState<string[]>([]);

  const [insuredFirstName, setInsuredFirstName] = useState("");
  const [insuredMiddleName, setInsuredMiddelName] = useState("");
  const [insuredLastName, setInsuredLastName] = useState("");
  const [insuredEmail, setInsuredEmail] = useState("");
  const [insuredPhoneNumber, setInsuredPhoneNumber] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessDba, setBusinessDba] = useState("");
  const [businessFein, setBusinessFein] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessWebsite, setBusinessWebsite] = useState("");
  const [mailingAddressLine1, setMailingAddressLine1] = useState("");
  const [mailingCity, setMailingCity] = useState("");
  const [mailingState, setMailingState] = useState("");
  const [mailingZipCode, setMailingZipCode] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [policyPremium, setPolicyPremium] = useState("");
  const [carrierName, setCarrierName] = useState<string>("");
  const [lineOfBusiness, setLineOfBusiness] = useState("");
  const [bindDate, setBindDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [effectiveDate, setEffectiveDate] = useState("");
  const [quoteNumber, setQuoteNumber] = useState("");
  const [sicCode, setSicCode] = useState("");
  const [eachAccident, setEachAccident] = useState("");
  const [diseasePolicyLimit, setDiseasePolicyLimit] = useState("");
  const [diseaseEachEmployee, setDiseaseEachEmployee] = useState("");

  const [insuredFirstNameError, setInsuredFirstNameError] = useState("");
  const [insuredLastNameError, setInsuredLastNameError] = useState("");
  const [insuredEmailError, setInsuredEmailError] = useState("");
  const [insuredPhoneNumberError, setInsuredPhoneNumberError] = useState("");
  const [businessNameError, setBusinessNameError] = useState("");
  const [businessDbaError, setBusinessDbaError] = useState("");
  const [businessFeinError, setBusinessFeinError] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState("");
  const [mailingAddressLine1Error, setmailingAddressLine1Error] = useState("");
  const [mailingCityError, setMailingCityError] = useState("");
  const [mailingStateError, setMailingStateError] = useState("");
  const [mailingZipCodeError, setMailingZipCodeError] = useState("");
  const [policyNumberError, setPolicyNumberError] = useState("");
  const [policyPremiumError, setPolicyPremiumError] = useState("");
  const [carrierNameError, setCarrierNameError] = useState("");
  const [lineOfBusinessError, setLineOfBusinessError] = useState("");
  const [quoteNumberError, setQuoteNumberError] = useState("");
  const [effectiveDateError, setEffectiveDateError] = useState("");
  const [expiryDateError, setExpiryDateError] = useState("");
  const [termError, setTermError] = useState("");
  const [term, setTerm] = useState("");

  const [carrierNameLoading, setcarrierNameLoading] = useState(false);
  const [hideSubmitButton, setHideSubmitButton] = useState(true);
  const [onSubmitLoader, setOnSubmitLoader] = useState(false);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [ncURL, setNcURL] = useState("");
  const [toggleSelectErrorMsg, setToggleSelectErrorMsg] = useState("");
  const [createProspectLoading, setCreateProspectLoading] = useState(false);

  const [submissionId, setSubmissionId] = useState("");
  const [currentBalance, setCurrentBalance] = useState("0");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [showUploadAgain, setShowUploadAgain] = useState(false);
  const [isBusinessEmail, setIsBusinessEmail] = useState(false);
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);

  let options1 = [
    { value: "Acord125", label: "Acord 125" },
    { value: "Acord126", label: "Acord 126" },
    { value: "Acord130", label: "Acord 130" },
    { value: "DecPage", label: "Dec Page" },
    { value: "QuoteLetter", label: "Quote Letter" },
  ];
  useEffect(() => {
    const token = CommonValues.GetToken();
    getCurrentBalance();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
  }, []);

  const getCurrentBalance = () => {
    setCurrentBalanceLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success == true) {
          setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };
  const OnChangeDocumentType = (value: any) => {
    if (value == "Select Document Type") {
      setDocumentType("");
    } else {
      setDocumentType(value);
    }
  };
  const validateSelectedFiles = (files: any) => {
    var regularExpression = /(?:\.([^.]+))?$/;
    var isValid = true;

    for (let index = 0, len = files.length; index < len; index++) {
      const fileExtension: any = regularExpression.exec(files[index].name);
      if (fileExtension === undefined || fileExtension[0] == "") {
        isValid = false;
        break;
      }
      if (fileExtension?.[1].toLowerCase().includes("pdf")) {
        isValid = true;
      } else {
        isValid = false;
        break;
      }
    }
    return isValid;
  };
  const onFileSelectHandler = (files: any) => {
    setFileName("");
    setFileNameTitle("");
    const isValidated = validateSelectedFiles(files);
    if (!isValidated) {
      toast.current.show({
        severity: "warn",
        detail: "Please select PDF files only.",
        life: 3000,
      });
      return false;
    }

    let fileList = [];
    let totalSize = 0;
    let currentFiles = uploadFile;
    let filename = files[0].name;
    if (filename.length > 50) {
      let shortenString = filename.substring(0, 50).concat("...");
      setFileName(shortenString);
      setFileNameTitle(filename);
    } else {
      setFileName(filename);
      setFileNameTitle(filename);
    }
    for (let i = 0, len = files.length; i < len; i++) {
      fileList.push({
        file: files[i],
        name: files[i].name,
        size: files[i].size,
      });
      totalSize = totalSize + files[i].size;
    }

    if (fileList.length > 0) {
      if (currentFiles.length > 0) {
        for (var i = 0, l = currentFiles.length; i < l; i++) {
          for (var j = 0; j < fileList.length; j++) {
            if (currentFiles[i].name === fileList[j].name) {
              fileList.splice(j, 1);
            }
          }
        }
      }
      let allFiles = currentFiles.concat(fileList);
      if (allFiles.length > uploadFileLimit) {
        allFiles = allFiles.slice(0, uploadFileLimit);
        toast.current.show({
          severity: "error",
          detail: "Multiple files cannot be uploaded at the same time; please upload a single file.",
          life: 3000,
        });
      }
      setUploadFile(allFiles);
      setFilesCount(allFiles.length);
      setRequestFileCount(allFiles.length);
      setFilesCount(allFiles.length);
    }
  };
  const removeFileFromList = () => {
    setUploadFile([]);
    setIsfileuploaded(false);
  };
  const createUploadRequest = () => {
    setLoading(true);

    if (uploadFile.length == 0) {
      toast.current.show({
        severity: "info",
        detail: "Please select at least one file to upload.",
        life: 3000,
      });
      setLoading(false);
      return;
    }
    if (documentType.length == 0) {
      toast.current.show({
        severity: "info",
        detail: "Please select document type.",
        life: 3000,
      });
      setLoading(false);

      return;
    }

    var FormData = require("form-data");
    var data = new FormData();
    data.append("file", uploadFile[0].file);
    data.append("documentType", documentType);
    let token = CommonValues.GetToken();

    var config = {
      method: "post",
      url: `${
        documentType === "DecPage" || documentType === "QuoteLetter"
          ? `${process.env.REACT_APP_DEC_PAGE_SCAN_URL}/api/extract/dec-page`
          : `${process.env.REACT_APP_DataFabric_Url}/api/OCR/nowcertssmartscan`
      }`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        if (response.status === 200) {
          if (response.data.success) {
            setIsFileUpload(true);
            setShowHideCancel(true);
            const data = response.data.nowcertsSmartScanResponse;
            let documentType = data.documentType;
            setSubmissionId(data.submissionId);
            if (documentType == "Acord125") {
              bindAccord125Model(data);
            } else if (documentType == "Acord130") {
              binAccord130Model(data);
            } else if (documentType == "Acord126") {
              bindAccord126Model(data);
            } else if (documentType == "DecPage" || documentType == "QuoteLetter") {
              bindDecPageModel(data);
            }
            setTimeout(() => {
              setLoading(false);
            }, 2000);

            getCurrentBalance();
          } else if (!response.data.success && response.data.errorMessage === "User does not have enough credits for Acord Smart Scan.") {
            setLoading(false);
            toast.current.show({
              severity: "info",
              detail: response.data.errorMessage,
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Something went wrong.",
            life: 3000,
          });
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.log(error);
        localStorage.removeItem("currentPage");
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Error while creating the request.",
            life: 3000,
          });
          setLoading(false);
        }
      });
  };
  const bindAccord125Model = (uploadFileResponse: any) => {
    let incomingState = uploadFileResponse.state;
    let state: any = Allstate.find((State) => State.code === incomingState) || Allstate.find((State) => State.name === incomingState);
    if (uploadFileResponse.fileUrl != "" && uploadFileResponse.fileUrl != null) {
      setUploadPdfUrl(uploadFileResponse.fileUrl);
    }
    if (uploadFileResponse.name != "" && uploadFileResponse.name != null) {
      setBusinessName(uploadFileResponse.name);
      setBusinessNameError("");
    }
    if (uploadFileResponse.firstName != "" && uploadFileResponse.firstName != null) {
      setInsuredFirstName(uploadFileResponse.firstName);
      setInsuredFirstNameError("");
    }
    if (uploadFileResponse.lastName != "" && uploadFileResponse.lastName != null) {
      setInsuredLastName(uploadFileResponse.lastName);
      setInsuredLastNameError("");
    }
    if (uploadFileResponse.phone != "" && uploadFileResponse.phone != null) {
      let phoneNumber = uploadFileResponse.phone.replace(/ /g, "");
      phoneNumber = formatPhoneNumber(phoneNumber);
      setInsuredPhoneNumber(phoneNumber);
      setInsuredPhoneNumberError("");
    }
    setInsuredEmail(uploadFileResponse.email);
    setInsuredEmailError("");
    if (uploadFileResponse.dba != "" && uploadFileResponse.dba != null) {
      setBusinessDba(uploadFileResponse.dba);
      setBusinessDbaError("");
    }
    if (uploadFileResponse.fein != "" && uploadFileResponse.fein != null) {
      setBusinessFein(uploadFileResponse.fein);
    }
    if (uploadFileResponse.website != "" && uploadFileResponse.website != null) {
      setBusinessWebsite(uploadFileResponse.website);
    }

    setBusinessEmail(
      uploadFileResponse.businessEmail != "" && uploadFileResponse.businessEmail != null ? uploadFileResponse.businessEmail : uploadFileResponse.email
    );

    if (
      (uploadFileResponse.businessEmail != "" && uploadFileResponse.businessEmail != null) ||
      (uploadFileResponse.email != "" && uploadFileResponse.email != null)
    ) {
      setIsBusinessEmail(true);
    }

    setBusinessEmailError("");
    if (uploadFileResponse.addressLine1 != "" && uploadFileResponse.addressLine1 != null) {
      setMailingAddressLine1(uploadFileResponse.addressLine1);
      setmailingAddressLine1Error("");
    }
    if (uploadFileResponse.city != "" && uploadFileResponse.city != null) {
      setMailingCity(uploadFileResponse.city.replace(",", ""));
      setMailingCityError("");
    }
    if (state != null && state != undefined && state != "") {
      setMailingState(state.code ? state.code : "");
      setMailingStateError("");
    }
    if (uploadFileResponse.zipCode != "" && uploadFileResponse.zipCode != null) {
      setMailingZipCode(uploadFileResponse.zipCode);
      setMailingZipCodeError("");
    }
    if (props.policyDetails == "Use Existing Policy") {
      setPolicyNumber(props.existingPolicyId);
      setPolicyNumberError("");
    } else {
      if (uploadFileResponse.policyNumber != "" && uploadFileResponse.policyNumber != null) {
        setPolicyNumber(uploadFileResponse.policyNumber);
        setPolicyNumberError("");
      }
    }

    if (uploadFileResponse.policyPremium != "" && uploadFileResponse.policyPremium != null) {
      setPolicyPremium(uploadFileResponse.policyPremium.replace(/[,$]/g, ""));
      setPolicyPremiumError("");
    } else {
      setPolicyPremium("0");
      setPolicyPremiumError("");
    }
    // if (uploadFileResponse.carrierName != "" && uploadFileResponse.carrierName != null) {
    //     setCarrierName(uploadFileResponse.carrierName);
    // }
    getNCCarrierList(uploadFileResponse.carrierName);
    if (uploadFileResponse.effectiveDate != "" && uploadFileResponse.effectiveDate != null) {
      setEffectiveDate(uploadFileResponse.effectiveDate);
      setEffectiveDateError("");
    }
    if (uploadFileResponse.proposedExpDate != "" && uploadFileResponse.proposedExpDate != null) {
      setExpiryDate(uploadFileResponse.proposedExpDate);
      setExpiryDateError("");
    }
    if (uploadFileResponse.lineOfBusiness != "" && uploadFileResponse.lineOfBusiness != null) {
      setLineOfBusiness(uploadFileResponse.lineOfBusiness);
      setLineOfBusinessError("");
    }
  };
  const bindAccord126Model = (uploadFileResponse: any) => {
    let incomingState = uploadFileResponse.state;
    let state: any = Allstate.find((State) => State.code === incomingState) || Allstate.find((State) => State.name === incomingState);
    if (uploadFileResponse.fileUrl != "" && uploadFileResponse.fileUrl != null) {
      setUploadPdfUrl(uploadFileResponse.fileUrl);
    }
    if (uploadFileResponse.applicantName != "" && uploadFileResponse.applicantName != null) {
      setBusinessName(uploadFileResponse.applicantName);
      setBusinessNameError("");
    }
    if (uploadFileResponse.effectiveDate != "" && uploadFileResponse.effectiveDate != null) {
      setEffectiveDate(uploadFileResponse.effectiveDate);
      setEffectiveDateError("");
    }
    if (uploadFileResponse.expiryDate != "" && uploadFileResponse.expiryDate != null) {
      setExpiryDate(uploadFileResponse.expiryDate);
      setExpiryDateError("");
    }
    if (uploadFileResponse.premium != "" && uploadFileResponse.premium != null) {
      setPolicyPremium(uploadFileResponse.premium.replace(/[,$]/g, "").replace(/\.00$/, "").trim());
      setPolicyPremiumError("");
    } else {
      setPolicyPremium("0");
      setPolicyPremiumError("");
    }
    setLineOfBusiness("General Liability ");
    getNCCarrierList(carrierName);
    setIsBusinessEmail(false);
  };
  const binAccord130Model = (uploadFileResponse: any) => {
    let incomingState = uploadFileResponse.state;
    let state: any = Allstate.find((State) => State.code === incomingState) || Allstate.find((State) => State.name === incomingState);
    if (uploadFileResponse.fileUrl != "" && uploadFileResponse.fileUrl != null) {
      setUploadPdfUrl(uploadFileResponse.fileUrl);
    }
    if (uploadFileResponse.businessName != "" && uploadFileResponse.businessName != null) {
      setBusinessName(uploadFileResponse.businessName);
      setBusinessNameError("");
    }
    if (uploadFileResponse.firstName != "" && uploadFileResponse.firstName != null) {
      setInsuredFirstName(uploadFileResponse.firstName);
      setInsuredFirstNameError("");
    }
    if (uploadFileResponse.lastName != "" && uploadFileResponse.lastName != null) {
      setInsuredLastName(uploadFileResponse.lastName);
      setInsuredLastNameError("");
    }
    if (uploadFileResponse.phone != "" && uploadFileResponse.phone != null) {
      let phoneNumber = uploadFileResponse.phone.replace(/ /g, "");
      phoneNumber = formatPhoneNumber(phoneNumber);
      setInsuredPhoneNumber(phoneNumber);
      setInsuredPhoneNumberError("");
    }
    setInsuredEmail(uploadFileResponse.email);
    setInsuredEmailError("");
    if (uploadFileResponse.dba != "" && uploadFileResponse.dba != null) {
      setBusinessDba(uploadFileResponse.dba);
      setBusinessDbaError("");
    }
    if (uploadFileResponse.fein != "" && uploadFileResponse.fein != null) {
      setBusinessFein(uploadFileResponse.fein);
    }
    if (uploadFileResponse.website != "" && uploadFileResponse.website != null) {
      setBusinessWebsite(uploadFileResponse.website);
    }

    setBusinessEmail(
      uploadFileResponse.businessEmail != "" && uploadFileResponse.businessEmail != null ? uploadFileResponse.businessEmail : uploadFileResponse.email
    );

    if (
      (uploadFileResponse.businessEmail != "" && uploadFileResponse.businessEmail != null) ||
      (uploadFileResponse.email != "" && uploadFileResponse.email != null)
    ) {
      setIsBusinessEmail(true);
    }

    setBusinessEmailError("");
    if (uploadFileResponse.addressLine1 != "" && uploadFileResponse.addressLine1 != null) {
      setMailingAddressLine1(uploadFileResponse.addressLine1);
      setmailingAddressLine1Error("");
    }
    if (uploadFileResponse.city != "" && uploadFileResponse.city != null) {
      setMailingCity(uploadFileResponse.city.replace(",", ""));
      setMailingCityError("");
    }
    if (state != null && state != undefined && state != "") {
      setMailingState(state.code ? state.code : "");
      setMailingStateError("");
    }
    if (uploadFileResponse.zipCode != "" && uploadFileResponse.zipCode != null) {
      setMailingZipCode(uploadFileResponse.zipCode);
      setMailingZipCodeError("");
    }
    if (props.policyDetails == "Use Existing Policy") {
      setPolicyNumber(props.existingPolicyId);
      setPolicyNumberError("");
    } else {
      if (uploadFileResponse.policyNumber != "" && uploadFileResponse.policyNumber != null) {
        setPolicyNumber(uploadFileResponse.policyNumber);
        setPolicyNumberError("");
      }
    }

    if (uploadFileResponse.policyPremium != "" && uploadFileResponse.policyPremium != null) {
      setPolicyPremium(uploadFileResponse.policyPremium.replace(/[,$]/g, ""));
      setPolicyPremiumError("");
    } else {
      setPolicyPremium("0");
      setPolicyPremiumError("");
    }
    // if (uploadFileResponse.carrierName != "" && uploadFileResponse.carrierName != null) {
    //     setCarrierName(uploadFileResponse.carrierName);
    // }
    if (uploadFileResponse.effectiveDate != "" && uploadFileResponse.effectiveDate != null) {
      setEffectiveDate(uploadFileResponse.effectiveDate);
      setEffectiveDateError("");
    }
    if (uploadFileResponse.proposedExpDate != "" && uploadFileResponse.proposedExpDate != null) {
      setExpiryDate(uploadFileResponse.proposedExpDate);
      setExpiryDateError("");
    }
    if (uploadFileResponse.lineOfBusiness != "" && uploadFileResponse.lineOfBusiness != null) {
      setLineOfBusiness(uploadFileResponse.lineOfBusiness);
      setLineOfBusinessError("");
    }
    getNCCarrierList(carrierName);
  };
  const bindDecPageModel = (uploadFileResponse: any) => {
    let incomingState = (uploadFileResponse.state ?? "").replace(",", "");
    let state: any = Allstate.find((State) => State.code === incomingState) || Allstate.find((State) => State.name === incomingState);
    if (uploadFileResponse.fileUrl != "" && uploadFileResponse.fileUrl != null) {
      setUploadPdfUrl(uploadFileResponse.fileUrl);
    }
    if (uploadFileResponse.firstName != "" || uploadFileResponse.firstName != null) {
      setInsuredFirstName(
        uploadFileResponse.firstName == null
          ? (uploadFileResponse.businessName ?? "").replace(",", "")
          : uploadFileResponse.firstName.replace("-", "")
      );
      setInsuredFirstNameError("");
    }
    if (uploadFileResponse.lastName != "" && uploadFileResponse.lastName != null) {
      setInsuredLastName(uploadFileResponse.lastName.replace(",", ""));
      setInsuredLastNameError("");
    }
    if (uploadFileResponse.phone != "" && uploadFileResponse.phone != null) {
      let phoneNumber = uploadFileResponse.phone.replace(/ /g, "");
      phoneNumber = formatPhoneNumber(phoneNumber);
      setInsuredPhoneNumber(phoneNumber);
      setInsuredPhoneNumberError("");
    }
    setInsuredEmail(uploadFileResponse.email);

    if (uploadFileResponse.dba != "" && uploadFileResponse.dba != null) {
      setBusinessDba(uploadFileResponse.dba);
    }
    if (uploadFileResponse.fein != "" && uploadFileResponse.fein != null) {
      setBusinessFein(uploadFileResponse.fein);
    }
    if (uploadFileResponse.website != "" && uploadFileResponse.website != null) {
      setBusinessWebsite(uploadFileResponse.website);
    }
    setBusinessEmail(
      uploadFileResponse.businessEmail != "" && uploadFileResponse.businessEmail != null ? uploadFileResponse.businessEmail : uploadFileResponse.email
    );

    if (
      (uploadFileResponse.businessEmail != "" && uploadFileResponse.businessEmail != null) ||
      (uploadFileResponse.email != "" && uploadFileResponse.email != null)
    ) {
      setIsBusinessEmail(true);
    }

    if (uploadFileResponse.addressLine1 != "" && uploadFileResponse.addressLine1 != null) {
      setMailingAddressLine1(uploadFileResponse.addressLine1.replace(",", ""));
      setmailingAddressLine1Error("");
    }
    if (uploadFileResponse.city != "" && uploadFileResponse.city != null) {
      setMailingCity(uploadFileResponse.city.replace(",", ""));
      setMailingCityError("");
    }
    if (state != null && state != undefined && state != "") {
      setMailingState(state.code ? state.code : "");
      setMailingStateError("");
    }
    if (uploadFileResponse.zipCode != "" && uploadFileResponse.zipCode != null) {
      setMailingZipCode(uploadFileResponse.zipCode.replace(",", ""));
      setMailingZipCodeError("");
    }
    if (props.policyDetails == "Use Existing Policy") {
      setPolicyNumber(props.existingPolicyId);
      setPolicyNumberError("");
    } else {
      if (uploadFileResponse.policyNumber != "" && uploadFileResponse.policyNumber != null) {
        setPolicyNumber(uploadFileResponse.policyNumber.replace(/\s/g, ""));
        setPolicyNumberError("");
      }
    }

    if (uploadFileResponse.quoteNumber != "" && uploadFileResponse.quoteNumber != null) {
      setQuoteNumber(uploadFileResponse.quoteNumber.replace(/\s/g, ""));
      setQuoteNumberError("");
    } else {
      if (uploadFileResponse.policyNumber != "" && uploadFileResponse.policyNumber != null) {
        setQuoteNumber(uploadFileResponse.policyNumber.replace(/\s/g, ""));
        setQuoteNumberError("");
      }
    }
    if (uploadFileResponse.policyPremium != "" && uploadFileResponse.policyPremium != null) {
      setPolicyPremium(uploadFileResponse.policyPremium.replace(/[,$]/g, ""));
      setPolicyPremiumError("");
    } else {
      setPolicyPremium("0");
      setPolicyPremiumError("");
    }
    if (uploadFileResponse.effectiveDate != "" && uploadFileResponse.effectiveDate != null) {
      setEffectiveDate(uploadFileResponse.effectiveDate);
      setEffectiveDateError("");
    }
    if (uploadFileResponse.proposedExpDate != "" && uploadFileResponse.proposedExpDate != null) {
      setExpiryDate(uploadFileResponse.proposedExpDate);
      setExpiryDateError("");
    }
    if (uploadFileResponse.lineOfBusinessName != "" && uploadFileResponse.lineOfBusinessName != null) {
      setLineOfBusiness(uploadFileResponse.lineOfBusinessName.replace(",", ""));
      setLineOfBusinessError("");
    }
    if (uploadFileResponse.businessName != "" && uploadFileResponse.businessName != null) {
      setBusinessName(uploadFileResponse.businessName.replace(",", ""));
      setBusinessNameError("");
    }
    if (uploadFileResponse.fein != "" && uploadFileResponse.fein != null) {
      setBusinessFein(uploadFileResponse.fein);
    }
    getNCCarrierList(uploadFileResponse.carrierName);
  };
  const getNCCarrierList = (carrierName: any) => {
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    setcarrierNameLoading(true);
    let token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/NowCert/getnowcertcarrierlist?carrierName=${carrierName}&ncToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setallCarrierNames(response.data.allCarrierNames);
        if (response.data.matchedCarrierName != "" && response.data.matchedCarrierName != "null" && response.data.matchedCarrierName != undefined) {
          let matchingcarriername: any = response.data.matchedCarrierName;
          if (matchingcarriername !== undefined) {
            if (!allCarrierNames.includes(matchingcarriername)) {
              setallCarrierNames((allCarrierNames) => [...allCarrierNames, matchingcarriername]);
            }
          }
          setCarrierName(matchingcarriername);
        }
        setcarrierNameLoading(false);
      })
      .catch((error: any) => {
        setcarrierNameLoading(false);
        console.log(error);
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Error while creating the request.",
            life: 3000,
          });
        }
      });
  };
  const onDropHandler = (event: any) => {
    event.preventDefault();
    onFileSelectHandler(event.dataTransfer.files);
    setShowHideCancel(true);
  };
  const handleTabClick = (tabName: any) => {
    setActiveTab(tabName);
  };
  const onChangeInsuredFirstName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setInsuredFirstName(Value);
  };
  const onChangeInsuredMiddleName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setInsuredMiddelName(Value);
  };
  const onChangeInsuredLastName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setInsuredLastName(Value);
    setInsuredLastNameError("");
  };
  const onChangeBusinessDba = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setBusinessDba(Value);
  };
  const onChangeInsuredEmail = (value: any) => {
    const Value = value.slice(0, 255);
    setInsuredEmail(Value);
    if (!isBusinessEmail) {
      setBusinessEmail(Value);
      setBusinessEmailError("");
    }
    setInsuredEmailError("");
  };
  const onChangeBusinessFein = (value: any) => {
    const Value = /^[+-]?\d*(?:[.,]\d*)?$/;
    if (Value.test(value)) {
      if (value.length > 9) {
        return;
      }
      setBusinessFein(value);
    }
  };
  const onChangeBusinessEmail = (value: any) => {
    const Value = value.slice(0, 255);
    setBusinessEmail(Value);
    setBusinessEmailError("");
  };
  const onChangeAgencyMailingAddress1 = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
    const Value = inputValue.slice(0, 255);
    setMailingAddressLine1(Value);
    setmailingAddressLine1Error("");
  };
  const onChangeAgencyMailingCity = (value: any) => {
    const Value = value.slice(0, 255);
    setMailingCity(Value);
    setMailingCityError("");
  };
  const onChangeAgencyMailingState = (value: any) => {
    setMailingState(value);
    setMailingStateError("");
  };
  const onChangeAgencyMailingZip = (value: any) => {
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      if (value.length > 5) {
        return;
      }
      setMailingZipCode(value);
      setMailingZipCodeError("");
    }
  };
  const onChangePolicyNumber = (value: any) => {
    const Value = value.slice(0, 255);
    if (props.policyDetails == "Use Existing Policy") {
      return;
    } else {
      setPolicyNumber(Value);
    }
    setPolicyNumberError("");
  };
  const onChangePolicyPremium = (value: any) => {
    const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    const numericValue = regex.test(value);
    if (numericValue) {
      setPolicyPremium(value);
      setPolicyPremiumError("");
    }
  };
  const onTermChange = (e: any) => {
    if (/^\d*$/.test(e.target.value)) {
      setTerm(e.target.value);
    }
  };
  const onChangeCarrierName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    if (value == "") {
      setCarrierName("");
    } else {
      setCarrierName(Value);
      setCarrierNameError("");
    }
  };
  const onChangeLineOfBusiness = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setLineOfBusiness(Value);
    setLineOfBusinessError("");
  };
  const onChangeQuoteNumber = (value: any) => {
    const Value = value.slice(0, 255);
    setQuoteNumber(Value);
    setQuoteNumberError("");
  };
  const onChangeEffectiveDate = (value: any) => {
    setEffectiveDate(value);
    setEffectiveDateError("");
    createExpiryDate(value);
  };

  const createExpiryDate = (value: string) => {
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value)) {
      console.error("Invalid date format. Expected 'MM/dd/yyyy'. Got:", value);
      return "Invalid date format";
    }

    const [month, day, year] = value.split("/").map((val) => parseInt(val, 10));

    const result = new Date(year, month - 1, day);

    if (isNaN(result.getTime())) {
      console.error("Invalid Date Object:", result);
      return "Invalid date";
    }

    return result.toISOString().split("T")[0];
  };

  const onChangeExpiryDate = (value: any) => {
    setExpiryDate(value);
    setExpiryDateError("");
  };
  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.currentTarget.value;
    const formattedValue = formatPhoneNumber(currentValue);
    setInsuredPhoneNumber(formattedValue);
    setInsuredPhoneNumberError("");
  };
  const onChangeBusinessName = (value: any) => {
    let inputValue = value.replace(/[^a-zA-Z ]/g, "");
    const Value = inputValue.slice(0, 255);
    setBusinessName(Value);
    setBusinessNameError("");
  };
  const formatPhoneNumber = (phoneNumber: string) => {
    const numericValue = phoneNumber.replace(/\D/g, "");
    const phoneNumberLength = numericValue.length;
    if (phoneNumberLength <= 3) {
      return numericValue;
    } else if (phoneNumberLength <= 6) {
      return `(${numericValue.slice(0, 3)})${numericValue.slice(3)}`;
    } else {
      return `(${numericValue.slice(0, 3)})${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
    }
  };
  const onSubmitClick = async () => {
    setOnSubmitLoader(true);
    setShowHideCancel(false);

    const executeSmartScanUpdate = () => {
      updateSmartScan();
    };

    const handleCreateInsured = async () => {
      const insuredDatabaseId: any = await createNewInsured();

      if (props.createQuoteForInsured) {
        await insertQuote(insuredDatabaseId);
      }

      if (props.createNewPolicyForInsured) {
        await createNewPolicy(insuredDatabaseId);
      }
    };

    const handleExistingInsured = async () => {
      if (props.createQuoteForInsured) {
        await insertQuote(props.insuredDatabaseId);
      }

      if (props.createNewPolicyForInsured) {
        await createNewPolicy(props.insuredDatabaseId);
      }
      if (props.createExistingPolicyForInsured && props.existingPolicyId != "") {
        await createNewPolicy(props.insuredDatabaseId);
      }
    };

    const handleCreateProspect = async () => {
      const insuredDatabaseId: any = await createNewProspect();

      if (props.createQuote) {
        await insertQuote(insuredDatabaseId);
      }

      executeSmartScanUpdate();
    };

    const handleExistingProspect = async () => {
      if (props.createQuote) {
        await insertQuote(props.prospectDatabaseId);
      }
      executeSmartScanUpdate();
    };

    if (props.createInsured) {
      await handleCreateInsured();
    } else if (props.insuredDatabaseId) {
      await handleExistingInsured();
    } else if (props.createProspect) {
      await handleCreateProspect();
    } else if (!props.createProspect && props.createQuote) {
      await insertQuote(props.prospectDatabaseId);
      executeSmartScanUpdate();
    } else if (props.prospectDatabaseId && props.createQuote) {
      await handleExistingProspect();
    } else {
      setToggleSelectErrorMsg("Select any one of the process Create Client / Create Quote");
      setTimeout(() => setToggleSelectErrorMsg(""), 6000);
      setOnSubmitLoader(false);
      setShowConfirmBox(false);
      setShowHideCancel(true);
    }

    // setOnSubmitLoader(false);
    // setShowConfirmBox(true);
    // setShowHideCancel(true);
  };

  // const onSubmitClick = async () => {
  //   setOnSubmitLoader(true);
  //   setShowHideCancel(false);
  //   if (
  //     props.createInsured == true &&
  //     props.createQuoteForInsured == false &&
  //     props.createNewPolicyForInsured == false &&
  //     props.createExistingPolicyForInsured == false
  //   ) {
  //     createNewInsured();
  //   } else if (
  //     props.createInsured == true &&
  //     props.createQuoteForInsured == true &&
  //     props.createNewPolicyForInsured == false &&
  //     props.createExistingPolicyForInsured == false
  //   ) {
  //     const insuredDatabaseId: any = await createNewInsured();
  //     setTimeout(() => insertQuote(insuredDatabaseId));
  //   } else if (
  //     props.createInsured == true &&
  //     props.createQuoteForInsured == false &&
  //     props.createNewPolicyForInsured == true &&
  //     props.createExistingPolicyForInsured == false
  //   ) {
  //     const insuredDatabaseId: any = await createNewInsured();
  //     setTimeout(() => createNewPolicy(insuredDatabaseId));
  //   } else if (
  //     props.createInsured == true &&
  //     props.createQuoteForInsured == true &&
  //     props.createNewPolicyForInsured == true &&
  //     props.createExistingPolicyForInsured == false
  //   ) {
  //     const insuredDatabaseId: any = await createNewInsured();
  //     setTimeout(() => insertQuote(insuredDatabaseId));
  //     setTimeout(() => createNewPolicy(insuredDatabaseId));
  //   } else if (props.insuredDatabaseId !== "" && props.createNewPolicyForInsured == true) {
  //     setTimeout(() => createNewPolicy(props.insuredDatabaseId));
  //   } else if (props.insuredDatabaseId !== "" && props.createQuoteForInsured == true) {
  //     setTimeout(() => insertQuote(props.insuredDatabaseId));
  //   } else if (props.insuredDatabaseId !== "" && props.createQuoteForInsured == true && props.createNewPolicyForInsured == true) {
  //     setTimeout(() => insertQuote(props.insuredDatabaseId));
  //     setTimeout(() => createNewPolicy(props.insuredDatabaseId));
  //   } else if (props.createProspect == true && props.createQuote == false) {
  //     createNewProspect();
  //     updateSmartScan();
  //   } else if (props.createProspect == true && props.createQuote == true) {
  //     const insuredDatabaseId: any = await createNewProspect();
  //     setTimeout(() => insertQuote(insuredDatabaseId));
  //     updateSmartScan();
  //   } else if (props.createProspect == false && props.createQuote == true) {
  //     insertQuote(props.prospectDatabaseId);
  //     updateSmartScan();
  //   } else {
  //     setToggleSelectErrorMsg("Select any one of the process Create Client / Create Quote");
  //     setTimeout(() => setToggleSelectErrorMsg(""), 6000);
  //     setOnSubmitLoader(false);
  //     setShowConfirmBox(false);
  //     setShowHideCancel(true);
  //   }
  // };
  const createNewInsured = async () => {
    setNcURL("");
    try {
      var token = CommonValues.GetToken();
      var ncToken = CommonValues.GetNCToken();
      var data = JSON.stringify({
        commercialName: businessName,
        firstName: "",
        lastName: "",
        dba: businessDba,
        addressLine1: mailingAddressLine1,
        addressLine2: "",
        state: mailingState,
        city: mailingCity,
        zipCode: mailingZipCode,
        eMail: insuredEmail,
        cellPhone: insuredPhoneNumber,
        fein: businessFein,
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/insertinsured?submissionId=${submissionId}&nowCertsToken=${ncToken}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);
      let insuredInfo = response.data.database_id;
      setNcURL(response.data.newInsuredLunchUrl);
      setShowUploadAgain(true);
      toast.current.show({
        severity: "success",
        detail: "Insured has been created successfully in NowCerts",
        life: 3000,
      });
      setOnSubmitLoader(false);
      setHideSubmitButton(false);
      setShowConfirmBox(false);
      return insuredInfo;
    } catch (error: any) {
      setOnSubmitLoader(false);

      console.log(error);
      let errorMessage = "";
      if (error.response != null) {
        if (error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else if (error.response.status == 400) {
          toast.current.show({
            severity: "error",
            detail: error.response.errors,
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            detail: "Please correct NowCerts username and password.",
            life: 3000,
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          detail: "Unknown error while creating insured inside the NowCerts.",
          life: 3000,
        });
      }
      let insuredInfo = "";
      return insuredInfo;
    }
  };
  const createNewProspect = async () => {
    try {
      setCreateProspectLoading(true);
      setNcURL("");
      var token = CommonValues.GetToken();
      var ncToken = CommonValues.GetNCToken();
      var data = JSON.stringify({
        commercialName: businessName,
        firstName: "",
        lastName: "",
        dba: businessDba,
        addressLine1: mailingAddressLine1,
        addressLine2: "",
        state: mailingState,
        city: mailingCity,
        zipCode: mailingZipCode,
        eMail: insuredEmail,
        cellPhone: insuredPhoneNumber,
        fein: businessFein,
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/createnewprospect?nowCertsToken=${ncToken}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);
      let insuredInfo = response.data.insuredDatabaseId;
      setNcURL(response.data.nowCertsUrlToLaunch);
      setShowUploadAgain(true);
      window.scrollTo(0, 1500);
      toast.current.show({
        severity: "success",
        detail: "Prospect has been created successfully in NowCerts.",
        life: 3000,
      });
      setOnSubmitLoader(false);
      setHideSubmitButton(false);
      setCreateProspectLoading(false);
      setShowConfirmBox(false);
      return insuredInfo;
    } catch (error: any) {
      console.log(error);
      let errorMessage = "";
      if (error.response != null) {
        if (error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else if (error.response.status == 400) {
          toast.current.show({
            severity: "error",
            detail: error.response.errors,
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            detail: "Please correct NowCerts username and password.",
            life: 3000,
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          detail: "Unknown error while creating insured inside the NowCerts.",
          life: 3000,
        });
      }
      setOnSubmitLoader(false);
      setShowConfirmBox(false);
      setCreateProspectLoading(false);
      let insuredInfo = "";
      return insuredInfo;
    }
  };
  const insertQuote = (insuredDatabaseId: any) => {
    let token = CommonValues.GetToken();
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";

    var data = JSON.stringify({
      insuredDatabaseId: insuredDatabaseId,
      insuredFirstName: insuredFirstName,
      insuredLastName: insuredLastName,
      insuredEmail: insuredEmail,
      effectiveDate: effectiveDate,
      expirationDate: moment(expiryDate).format("MM/DD/YYYY"),
      bindDate: "",
      insuredCommercialName: businessName,
      lineOfBusinessName: lineOfBusiness,
      carrierName: carrierName,
      premium: policyPremium,
      quoteNumber: policyNumber,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/NowCert/insertquote?&ncToken=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        toast.current.show({
          severity: "success",
          detail: "Quote Created Successfully.",
          life: 3000,
        });
        setOnSubmitLoader(false);
        setShowConfirmBox(false);
        setShowUploadAgain(true);
        setHideSubmitButton(false);
      })
      .catch((error: any) => {
        console.log(error);
        setOnSubmitLoader(false);
        localStorage.removeItem("currentPage");
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Error while quote creating the request.",
            life: 3000,
          });
        }
      });
  };

  const createNewPolicy = async (insuredDataBaseId: any) => {
    //const expirationDate = createExpiryDate(effectiveDate);
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var data = {
      insuredDatabaseId: insuredDataBaseId,
      firstName: insuredFirstName,
      lastName: insuredLastName,
      number: policyNumber,
      effectiveDate: moment(effectiveDate).format("MM/DD/YYYY"),
      expirationDate: moment(expiryDate).format("MM/DD/YYYY"),
      bindDate: "",
      businessType: 0,
      billingType: 0,
      mgaName: "",
      carrierName: carrierName,
      premium: policyPremium,
      lineOfBusinessName: lineOfBusiness,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/addpolicy?nowCertsToken=${nowCertsToken}&submissionId=${submissionId}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        toast.current.show({
          severity: "success",
          detail: "Policy has been created successfully in NowCerts",
          life: 3000,
        });
        setOnSubmitLoader(false);
      })
      .catch((error) => {
        let errorMessage = "";
        setOnSubmitLoader(false);

        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while adding new policy",
              life: 3000,
            });
          }
        }
      });
  };
  const oncancelClicks = () => {
    setShowConfirmBox(false);
  };
  const OnSubmit = () => {
    if (checkValidation()) {
      setShowConfirmBox(true);
    }
  };
  const onCancel = () => {
    setDocumentType("");
    setActiveTab("Insured");
    setIsFileUpload(false);
    setInsuredEmail("");
    setInsuredFirstName("");
    setInsuredMiddelName("");
    setInsuredLastName("");
    setPolicyNumber("");
    setPolicyPremium("");
    setCarrierName("");
    setLineOfBusiness("");
    setExpiryDate("");
    setEffectiveDate("");
    setInsuredPhoneNumber("");
    setBusinessDba("");
    setBusinessName("");
    setBusinessFein("");
    setBusinessWebsite("");
    setBusinessEmail("");
    setMailingAddressLine1("");
    setMailingCity("");
    setMailingState("");
    setMailingZipCode("");
    setUploadFile([]);
    setFileName("");
    setFileNameTitle("");
    setInsuredFirstNameError("");
    setInsuredLastNameError("");
    setInsuredEmailError("");
    setInsuredPhoneNumberError("");
    setBusinessNameError("");
    setBusinessDbaError("");
    setBusinessFeinError("");
    setBusinessEmailError("");
    setmailingAddressLine1Error("");
    setMailingCityError("");
    setMailingStateError("");
    setMailingZipCodeError("");
    setInsuredPhoneNumber("");
    setPolicyNumberError("");
    setPolicyPremiumError("");
    setCarrierNameError("");
    setLineOfBusinessError("");
    setEffectiveDateError("");
    setExpiryDateError("");
    setNcURL("");
    setHideSubmitButton(true);
    setShowHideCancel(false);
    setEachAccident("");
    setDiseaseEachEmployee("");
    setDiseasePolicyLimit("");
    setSicCode("");
    setQuoteNumberError("");
  };
  const checkValidation = () => {
    let formIsValid = true;
    let activeInsuredTabFlag = false;
    setInsuredFirstNameError("");
    setInsuredLastNameError("");
    setInsuredEmailError("");
    setInsuredPhoneNumberError("");
    setBusinessNameError("");
    setBusinessDbaError("");
    setBusinessFeinError("");
    setBusinessEmailError("");
    setmailingAddressLine1Error("");
    setMailingCityError("");
    setMailingStateError("");
    setMailingZipCodeError("");
    setPolicyNumberError("");
    setPolicyPremiumError("");
    setCarrierNameError("");
    setLineOfBusinessError("");
    setEffectiveDateError("");
    setExpiryDateError("");
    setQuoteNumberError("");

    // if (insuredFirstName == "" || insuredFirstName == null) {
    //   formIsValid = false;
    //   activeInsuredTabFlag = true;
    //   setInsuredFirstNameError("First name is required");
    //   window.scroll(0, 0);
    // }
    // if (insuredLastName == "" || insuredLastName == null) {
    //   formIsValid = false;
    //   activeInsuredTabFlag = true;
    //   setInsuredLastNameError("Last name is required");
    //   window.scroll(0, 0);
    // }
    // if (insuredEmail == "" || insuredEmail == null) {
    //   formIsValid = false;
    //   activeInsuredTabFlag = true;
    //   setInsuredEmailError("Email is required");
    //   window.scroll(0, 0);
    // } else {
    // const regex =
    //   /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    // if (regex.test(insuredEmail) == false) {
    //   activeInsuredTabFlag = true;
    //   formIsValid = false;
    //   setInsuredEmailError("Please enter valid Email.");
    //   window.scroll(0, 0);
    // }
    // }
    // if (insuredPhoneNumber == "" || insuredPhoneNumber == null) {
    //   formIsValid = false;
    //   activeInsuredTabFlag = true;
    //   setInsuredPhoneNumberError("Phone number is required");
    //   window.scroll(0, 0);
    // } else {
    // const regexPhoneNumber = /^\(\d{3}\)\d{3}-\d{5}$/;
    // if (regexPhoneNumber.test(insuredPhoneNumber) == false) {
    //   formIsValid = false;
    //   activeInsuredTabFlag = true;
    //   setInsuredPhoneNumberError("Please enter valid phone number : (###)###-####");
    //   window.scroll(0, 0);
    // }
    // }
    if (businessName == "" || businessName == null) {
      formIsValid = false;
      activeInsuredTabFlag = true;
      setBusinessNameError("Business name is required");
      window.scroll(0, 0);
    }
    if (businessEmail == "" || businessEmail == null) {
      formIsValid = false;
      activeInsuredTabFlag = true;
      setBusinessEmailError("Business email is required");
      window.scroll(0, 0);
    } else {
      const regex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
      if (regex.test(businessEmail) == false) {
        formIsValid = false;
        activeInsuredTabFlag = true;
        setBusinessEmailError("Please enter valid email.");
        window.scroll(0, 0);
      }
    }
    if (mailingAddressLine1 == "" || mailingAddressLine1 == null) {
      formIsValid = false;
      activeInsuredTabFlag = true;
      setmailingAddressLine1Error("Address is required");
    }
    if (mailingCity == "" || mailingCity == null) {
      formIsValid = false;
      activeInsuredTabFlag = true;
      setMailingCityError("City is required");
    }
    if (mailingState == "" || mailingState == null) {
      formIsValid = false;
      activeInsuredTabFlag = true;
      setMailingStateError("State is required");
    }
    if (mailingZipCode == "" || mailingZipCode == null) {
      formIsValid = false;
      activeInsuredTabFlag = true;
      setMailingZipCodeError("Zip code is required");
    }

    if (activeInsuredTabFlag) {
      // setActiveFirstTabError(true)
      setActiveTab("Insured");
    } else {
      if (props.createQuote == true || props.createProspect == false) {
        if (policyNumber == "" || policyNumber == null) {
          formIsValid = false;
          setActiveTab("Policy");
          setPolicyNumberError("Policy number is required");
        }
        if (policyPremium == "" || policyPremium == null) {
          formIsValid = false;
          setActiveTab("Policy");
          setPolicyPremiumError("Policy premium is required");
        }
        if (carrierName == "" || carrierName == null) {
          formIsValid = false;
          setActiveTab("Policy");
          setCarrierNameError("Carrier name is required");
        }
        if (lineOfBusiness == "" || lineOfBusiness == null) {
          formIsValid = false;
          setActiveTab("Policy");
          setLineOfBusinessError("LOB name is required");
        }
        if (effectiveDate == "" || effectiveDate == null) {
          formIsValid = false;
          setActiveTab("Policy");
          setEffectiveDateError("Effective date is required");
        }
        if (expiryDate == "" || expiryDate == null) {
          formIsValid = false;
          setActiveTab("Policy");
          setExpiryDateError("Expiration date is required");
        }
        // if (quoteNumber == "" || quoteNumber == null) {
        //   formIsValid = false;
        //   setActiveTab("Policy");
        //   setQuoteNumberError("Quote number is required");
        // }
      }
    }
    return formIsValid;
  };
  const onOpenClick = () => {
    window.open(ncURL, "_blank");
  };

  const uploadAgain = () => {
    setActiveTab("Insured");
    setIsFileUpload(false);
    setInsuredEmail("");
    setInsuredFirstName("");
    setInsuredMiddelName("");
    setInsuredLastName("");
    setPolicyNumber("");
    setPolicyPremium("");
    setCarrierName("");
    setLineOfBusiness("");
    setExpiryDate("");
    setEffectiveDate("");
    setInsuredPhoneNumber("");
    setBusinessDba("");
    setBusinessName("");
    setBusinessFein("");
    setBusinessWebsite("");
    setBusinessEmail("");
    setMailingAddressLine1("");
    setMailingCity("");
    setMailingState("");
    setMailingZipCode("");
    setUploadFile([]);
    setFileName("");
    setFileNameTitle("");
    setDocumentType("");
    setInsuredFirstNameError("");
    setInsuredLastNameError("");
    setInsuredEmailError("");
    setInsuredPhoneNumberError("");
    setBusinessNameError("");
    setBusinessDbaError("");
    setBusinessFeinError("");
    setBusinessEmailError("");
    setmailingAddressLine1Error("");
    setMailingCityError("");
    setMailingStateError("");
    setMailingZipCodeError("");
    setInsuredPhoneNumber("");
    setPolicyNumberError("");
    setPolicyPremiumError("");
    setCarrierNameError("");
    setLineOfBusinessError("");
    setEffectiveDateError("");
    setExpiryDateError("");
    setNcURL("");
    setHideSubmitButton(true);
    setEachAccident("");
    setDiseaseEachEmployee("");
    setDiseasePolicyLimit("");
    setSicCode("");
    setQuoteNumber("");
    setQuoteNumberError("");
    setTerm("");
    setShowUploadAgain(false);
  };
  const Allstate = [
    { name: "Alaska", code: "AK" },
    { name: "Alabama", code: "AL" },
    { name: "Arkansas", code: "AR" },
    { name: "Arizona", code: "AZ" },
    { name: "California", code: "CA" },
    { name: "Colorado", code: "CO" },
    { name: "Connecticut", code: "CT" },
    { name: "DistrictofColumbia", code: "DC" },
    { name: "Delaware", code: "DE" },
    { name: "Florida", code: "FL" },
    { name: "Georgia", code: "GA" },
    { name: "Hawaii", code: "HI" },
    { name: "Iowa", code: "IA" },
    { name: "Idaho", code: "ID" },
    { name: "Illinois", code: "IL" },
    { name: "Indiana", code: "IN" },
    { name: "Kansas", code: "KS" },
    { name: "Kentucky", code: "KY" },
    { name: "Louisiana", code: "LA" },
    { name: "Massachusetts", code: "MA" },
    { name: "Maryland", code: "MD" },
    { name: "Maine", code: "ME" },
    { name: "Michigan", code: "MI" },
    { name: "Minnesota", code: "MN" },
    { name: "Missouri", code: "MO" },
    { name: "Mississippi", code: "MS" },
    { name: "Montana", code: "MT" },
    { name: "NorthCarolina", code: "NC" },
    { name: "NorthDakota", code: "ND" },
    { name: "Nebraska", code: "NE" },
    { name: "NewHampshire", code: "NH" },
    { name: "NewJersey", code: "NJ" },
    { name: "NewMexico", code: "NM" },
    { name: "Nevada", code: "NV" },
    { name: "NewYork", code: "NY" },
    { name: "Ohio", code: "OH" },
    { name: "Oklahoma", code: "OK" },
    { name: "Oregon", code: "OR" },
    { name: "Pennsylvania", code: "PA" },
    { name: "RhodeIsland", code: "RI" },
    { name: "SouthCarolina", code: "SC" },
    { name: "SouthDakota", code: "SD" },
    { name: "Tennessee", code: "TN" },
    { name: "Texas", code: "TX" },
    { name: "Utah", code: "UT" },
    { name: "Virginia", code: "VA" },
    { name: "Vermont", code: "VT" },
    { name: "Washington", code: "WA" },
    { name: "Wisconsin", code: "WI" },
    { name: "WestVirginia", code: "WV" },
    { name: "Wyoming", code: "WY" },
  ];
  const updateSmartScan = () => {
    let token = CommonValues.GetToken();
    var data = {
      submissionId: submissionId,
      createProspect: props.createProspect,
      createQuote: props.createQuote,
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/NowCert/updatesmartscan`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {})
      .catch((error: any) => {
        console.log(error);
        localStorage.removeItem("currentPage");
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Error while smart scan updating the request.",
            life: 3000,
          });
        }
      });
  };

  const onFeedbackClick = (e: any) => {
    feedbackRef.current?.toggle(e);
  };

  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback, submissionId: submissionId };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };
  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked, submissionId: submissionId };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
  };

  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked, submissionId: submissionId };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
  };

  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };

  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else {
      setFeedBackError("");
      onSaveFeedback(rowData);
      setFeedbackLoading(true);
    }
  };

  const onSaveRatings = (rowData: any) => {
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: rowData.submissionId,
      Feedback: feedbackVal,
      IsLiked: rowData.isLiked,
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/smartscan/isliked`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Ratings Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };

  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: rowData.submissionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/smartscan/feedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Feedback Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);

        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="smartscan-main-page">
        <Header pagename="smart-scan" />
        <div className="content-wrapper ">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div  ">
            <div className="col-12">
              <SmartScanMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <SmartScanSidebar pagename="smart-scan" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  d-flex  align-items-end text-12 position-relative">
                  <div className="col-12  p-0">
                    <div className="row d-flex   justify-content-end scan-search-row mx-auto ">
                      {" "}
                      <div className="col-10 d-flex  align-items-center">
                        <span>
                          {" "}
                          <AtkButton label="Back" onClick={props.onBackClick} className="policy-back-btn w-auto  me-3" />
                        </span>
                        {props.prospectDetails != "" && props.prospectDetails != null ? (
                          <>
                            <span className=" me-3">
                              <label>
                                <b>Prospect: </b> &nbsp;
                              </label>
                              {props.prospectDetails}
                            </span>
                            {props.quoteDetails != "" ? (
                              <span>
                                <label>
                                  <b>Quote: </b> &nbsp;
                                </label>
                                {props.quoteDetails}
                              </span>
                            ) : null}
                          </>
                        ) : props.insuredDetails != "" && props.insuredDetails != null ? (
                          <>
                            <span className=" me-3">
                              <label>
                                <b>Insured: </b> &nbsp;
                              </label>
                              {props.insuredDetails}
                            </span>
                            {props.quoteDetailsForInsured != "" ? (
                              <span>
                                <label>
                                  <b>Quote: </b> &nbsp;
                                </label>
                                {props.quoteDetailsForInsured} &nbsp;
                              </span>
                            ) : null}
                            <span>
                              <label>
                                <b>Policy: </b> &nbsp;
                              </label>
                              {props.policyDetails}
                            </span>
                          </>
                        ) : null}
                      </div>
                      <div className="col-2 text-center balance-span">
                        <label>Current Balance</label>
                        {currentBalanceLoading ? (
                          <span className="balance-spinner d-flex  align-items-center justify-content-center ">
                            <AtkLoader />
                          </span>
                        ) : (
                          <span className=" d-block w-auto ">${currentBalance}</span>
                        )}
                        <small className="d-block w-auto text-danger mt-0">25 cent will be charged per usage</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  d-flex justify-content-end align-items-center text-12 position-relative mx-auto mt-2">
                  <div className="col-12">
                    <div className="row m-3">
                      {isFileUpload ? (
                        <div className="col">
                          <iframe
                            src={uploadPdfUrl}
                            style={{
                              overflow: "scroll",
                              height: "100%",
                              width: "100%",
                            }}
                          ></iframe>
                        </div>
                      ) : (
                        <>
                          <div
                            className="col uploadNowcertsDoc25 me-3 "
                            onDrop={(e) => {
                              onDropHandler(e);
                            }}
                            onDragOver={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <div className="row w-100 ">
                              <div className="col-12 smart-scan-dropdown pb-0">
                                <select className="smart-scan-dropdown" onChange={(e) => OnChangeDocumentType(e.target.value)}>
                                  <option>Select Document Type</option>
                                  {options1.map((item: any) => (
                                    <option key={item.value} value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="col-12 mt-1 mb-2 text-center">
                                {errorMessageDocumentType.length == 0 ? null : (
                                  <span className="text-danger text-12">{errorMessageDocumentType}</span>
                                )}
                              </div>

                              {uploadFile.length > 0 ? (
                                <div className="col-12">
                                  {uploadFile.map((file: any, index: any) => (
                                    <div className="show-pdf-icon">
                                      <div className="scan-file-name">
                                        <div className="preview-icon">
                                          <img src={PreviewIcon} />
                                        </div>
                                        <div>
                                          <div title={fileNameTitle}>{fileName}</div>
                                          <div>
                                            {(parseInt(file.size) / 1000).toFixed(0)}
                                            KB
                                          </div>
                                        </div>
                                      </div>
                                      {uploadComplete ? (
                                        <div>
                                          <span className="uploaded">Uploaded</span>
                                        </div>
                                      ) : (
                                        uploadingFiles == false && (
                                          <div onClick={() => removeFileFromList()} className="show-cursor">
                                            <img src={RemoveIcon} />
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <>
                                  <>
                                    <div className="row" style={{ textAlign: "center" }}>
                                      <div className="col-12">
                                        <img src={PfdIcon} alt="" width="70" />
                                      </div>

                                      <div className="col-12 mt-4">
                                        <div className="col-12">
                                          <span className="drag-and-drop">Drag & Drop file here</span>
                                        </div>
                                        <div className="col-12 my-2">
                                          <span
                                            style={{
                                              color: "black",
                                              fontSize: "large",
                                            }}
                                          >
                                            or
                                          </span>
                                        </div>
                                        <label className="mb-0 show-cursor">
                                          <span className="browse">Browse PDF</span>
                                          <input
                                            type="file"
                                            name="filefield"
                                            accept=".pdf"
                                            id="bulkFileUploader"
                                            style={{
                                              visibility: "hidden",
                                              display: "none",
                                            }}
                                            onChange={(e) => {
                                              onFileSelectHandler(e.target.files);
                                            }}
                                          />
                                        </label>
                                      </div>
                                      <div className="coi-info">
                                        <span className="text-14 mt-2 info">
                                          Supported document types: Acord 125, Acord 126 , Acord 130 and Dec Page.
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                </>
                              )}

                              <div className="col-12 text-center">
                                {isfileuploaded ? null : (
                                  <>
                                    {uploadingFiles == false &&
                                      incompleteRequestData == false &&
                                      uploadingRetry == false &&
                                      uploadFile.length > 0 && (
                                        <div
                                          className="d-flex justify-content-center"
                                          style={{
                                            width: "100%",
                                            padding: "20px",
                                          }}
                                        >
                                          {loading == false ? (
                                            <>
                                              <AtkButtonIconBefore
                                                label="Upload"
                                                className="pf-secondary-btn"
                                                onClick={() => {
                                                  createUploadRequest();
                                                }}
                                              />
                                            </>
                                          ) : (
                                            <div className="d-flex justify-content-center align-items-center p-2">
                                              <AtkLoader></AtkLoader>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>

                              <div className="col-12 text-12 mt-3">
                                <AtkMessage
                                  appearance="information"
                                  messageText={
                                    "This tool works best with readable PDFs. Please note that scanned or image-based PDFs may not be processed. For scanned documents, consider converting them to a readable format using OCR tools before uploading."
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div
                        className="col p-2 ms-2"
                        style={{
                          border: "1px solid darkgrey",
                          borderRadius: "30px",
                          minHeight: "66vh",
                        }}
                      >
                        <div className="row p-0 d-flex align-items-center mx-auto smartscan-tab">
                          <div className="col-6 d-flex align-items-center justify-content-start">
                            <span
                              className={activeTab === "Insured" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                              onClick={() => {
                                handleTabClick("Insured");
                              }}
                            >
                              Insured Details
                            </span>
                            <span
                              className={activeTab === "Policy" ? "policy-name-span active show-cursor" : "policy-name-span show-cursor"}
                              onClick={() => {
                                handleTabClick("Policy");
                              }}
                            >
                              Policy Details
                            </span>
                          </div>
                          <div className="col-6 "></div>
                        </div>

                        {activeTab === "Insured" ? (
                          <>
                            <div className="row  my-2 mx-auto">
                              <div className="col-12">
                                <span className="text-14 ">
                                  <b>Insured Information</b>
                                </span>
                              </div>
                            </div>
                            <div className="row smartscan-block  mx-auto ">
                              <div
                                className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${insuredFirstNameError.length > 0 ? " validation-div" : ""}`}
                              >
                                <label>First Name</label>
                                <TextField
                                  className="input-field"
                                  type="text"
                                  value={insuredFirstName}
                                  placeholder="Enter first name"
                                  onChange={(e: any) => {
                                    onChangeInsuredFirstName(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12">{insuredFirstNameError}</span>
                              </div>

                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                <label>Middle Name</label>
                                <TextField
                                  className="input-field"
                                  type="text"
                                  value={insuredMiddleName}
                                  placeholder="Enter middle name"
                                  onChange={(e: any) => {
                                    onChangeInsuredMiddleName(e.target.value);
                                  }}
                                />
                              </div>

                              <div
                                className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${insuredLastNameError.length > 0 ? " validation-div" : ""}`}
                              >
                                <label>Last Name</label>
                                <TextField
                                  placeholder="Enter last name"
                                  className="input-field"
                                  type="text"
                                  value={insuredLastName}
                                  onChange={(e: any) => {
                                    onChangeInsuredLastName(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12">{insuredLastNameError}</span>
                              </div>

                              <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${insuredEmailError.length > 0 ? " validation-div" : ""}`}>
                                <label>Email</label>
                                <TextField
                                  className="input-field"
                                  type="text"
                                  value={insuredEmail}
                                  placeholder="Enter email"
                                  onChange={(e: any) => {
                                    onChangeInsuredEmail(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12">{insuredEmailError}</span>
                              </div>

                              <div
                                className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${
                                  insuredPhoneNumberError.length > 0 ? " validation-div" : ""
                                }`}
                              >
                                <label>Phone Number</label>
                                <TextField
                                  className="input-field"
                                  placeholder="Enter phone number"
                                  type="text"
                                  value={insuredPhoneNumber}
                                  onChange={handlePhoneNumberChange}
                                />
                                <span className="text-danger text-12">{insuredPhoneNumberError}</span>
                              </div>
                            </div>

                            <div className="row  my-2  mx-auto">
                              <div className="col-12">
                                <span className="text-14 ">
                                  <b>Business Information</b>
                                </span>
                              </div>
                            </div>
                            <div className="row smartscan-block  mx-auto">
                              <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${businessNameError.length > 0 ? " validation-div" : ""}`}>
                                <span>Business Name</span>
                                <span className="text-danger"> *</span>
                                <TextField
                                  className="input-field"
                                  type="text"
                                  placeholder="Enter business name"
                                  value={businessName}
                                  onChange={(e: any) => {
                                    onChangeBusinessName(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12">{businessNameError}</span>
                              </div>

                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                {" "}
                                <span>DBA</span>
                                <TextField
                                  className="input-field"
                                  type="text"
                                  value={businessDba}
                                  placeholder="Enter dba name"
                                  onChange={(e: any) => {
                                    onChangeBusinessDba(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12 btisErrorText">{businessDbaError}</span>
                              </div>

                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                {" "}
                                <span>Fein</span>
                                <TextField
                                  className="input-field"
                                  type="text"
                                  value={businessFein}
                                  placeholder="Enter fein"
                                  onChange={(e: any) => {
                                    onChangeBusinessFein(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12 btisErrorText">{businessFeinError}</span>
                              </div>

                              <div
                                className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${businessEmailError.length > 0 ? " validation-div" : ""}`}
                              >
                                <span>Business Email</span>
                                <span className="text-danger"> *</span>
                                <TextField
                                  className="input-field"
                                  type="text"
                                  value={businessEmail}
                                  placeholder="Enter business email"
                                  onChange={(e: any) => {
                                    onChangeBusinessEmail(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12 btisErrorText">{businessEmailError}</span>
                              </div>
                            </div>

                            <div className="row  my-2  mx-auto">
                              <div className="col-12">
                                <span className="text-14 ">
                                  <b>Address Information</b>
                                </span>
                              </div>
                            </div>
                            <div className="row smartscan-block  mx-auto">
                              <div
                                className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${
                                  mailingAddressLine1Error.length > 0 ? " validation-div" : ""
                                }`}
                              >
                                <span>Address</span>
                                <span className="text-danger"> *</span>
                                <TextField
                                  className="input-field"
                                  type="text"
                                  value={mailingAddressLine1}
                                  placeholder="Enter address"
                                  onChange={(e: any) => {
                                    onChangeAgencyMailingAddress1(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12">{mailingAddressLine1Error}</span>
                              </div>

                              <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${mailingCityError.length > 0 ? " validation-div" : ""}`}>
                                <span>City</span>
                                <span className="text-danger"> *</span>
                                <TextField
                                  className="input-field"
                                  type="text"
                                  value={mailingCity}
                                  placeholder="Enter city"
                                  onChange={(e: any) => {
                                    onChangeAgencyMailingCity(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12 btisErrorText">{mailingCityError}</span>
                              </div>

                              <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${mailingStateError.length > 0 ? " validation-div" : ""}`}>
                                <span>State</span>
                                <span className="text-danger"> *</span>
                                <select
                                  style={{
                                    width: "102%",
                                    color: "#5c5a5a",
                                    fontWeight: "500",
                                    height: "34px",
                                  }}
                                  className="input-field"
                                  value={mailingState}
                                  onChange={(e: any) => {
                                    onChangeAgencyMailingState(e.target.value);
                                  }}
                                >
                                  <option value="" selected>
                                    Select an option
                                  </option>

                                  {Allstate.map((state) => (
                                    <option key={state.code} value={state.code}>
                                      {state.code + "-" + state.name}
                                    </option>
                                  ))}
                                </select>
                                <span className="text-danger text-12 btisErrorText">{mailingStateError}</span>
                              </div>

                              <div
                                className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${mailingZipCodeError.length > 0 ? " validation-div" : ""}`}
                              >
                                <span>Zip Code</span>
                                <span className="text-danger"> *</span>
                                <TextField
                                  className="input-field"
                                  type="text"
                                  value={mailingZipCode}
                                  placeholder="Enter zip code"
                                  onChange={(e: any) => {
                                    onChangeAgencyMailingZip(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12 btisErrorText">{mailingZipCodeError}</span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="row  my-2 mx-auto">
                              <div className="col-12">
                                <span className="text-14 ">
                                  <b>Insured Information</b>
                                </span>
                              </div>
                            </div>
                            <div className="row smartscan-block mx-auto">
                              <div
                                className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${insuredFirstNameError.length > 0 ? " validation-div" : ""}`}
                              >
                                <label>First Name</label>
                                <TextField
                                  className="input-field"
                                  type="text"
                                  value={insuredFirstName}
                                  placeholder="Enter first name"
                                  onChange={(e: any) => {
                                    onChangeInsuredFirstName(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12">{insuredFirstNameError}</span>
                              </div>

                              <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                {" "}
                                <label>Middle Name</label>
                                <TextField
                                  className="input-field"
                                  type="text"
                                  value={insuredMiddleName}
                                  placeholder="Enter middle name"
                                  onChange={(e: any) => {
                                    onChangeInsuredMiddleName(e.target.value);
                                  }}
                                />
                              </div>

                              <div
                                className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${insuredLastNameError.length > 0 ? " validation-div" : ""}`}
                              >
                                <label>Last Name</label>
                                <TextField
                                  placeholder="Enter last name"
                                  className="input-field"
                                  type="text"
                                  value={insuredLastName}
                                  onChange={(e: any) => {
                                    onChangeInsuredLastName(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12">{insuredLastNameError}</span>
                              </div>

                              <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${insuredEmailError.length > 0 ? " validation-div" : ""}`}>
                                <label>Email</label>
                                <TextField
                                  className="input-field"
                                  type="text"
                                  value={insuredEmail}
                                  placeholder="Enter email"
                                  onChange={(e: any) => {
                                    onChangeInsuredEmail(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12">{insuredEmailError}</span>
                              </div>

                              <div
                                className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${
                                  insuredPhoneNumberError.length > 0 ? " validation-div" : ""
                                }`}
                              >
                                <label>Phone Number</label>
                                <TextField
                                  className="input-field"
                                  placeholder="Enter phone number"
                                  type="text"
                                  value={insuredPhoneNumber}
                                  onChange={handlePhoneNumberChange}
                                />
                                <span className="text-danger text-12">{insuredPhoneNumberError}</span>
                              </div>
                            </div>

                            <div className="row  my-2 mx-auto">
                              <div className="col-12">
                                <span className="text-14 ">
                                  <b>Policy Information</b>
                                </span>
                              </div>
                            </div>
                            <div className="row smartscan-block mx-auto">
                              <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${policyNumberError.length > 0 ? " validation-div" : ""}`}>
                                <span>Policy Number</span>
                                <span className="text-danger"> *</span>
                                <TextField
                                  className="input-field"
                                  type="text"
                                  value={policyNumber}
                                  placeholder="Enter policy number"
                                  onChange={(e: any) => {
                                    onChangePolicyNumber(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12">{policyNumberError}</span>
                              </div>

                              <div
                                className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${policyPremiumError.length > 0 ? " validation-div" : ""}`}
                              >
                                <span>Policy Premium ($)</span>
                                <span className="text-danger"> *</span>
                                <TextField
                                  className="input-field"
                                  type="text"
                                  value={policyPremium}
                                  placeholder="Enter policy premium"
                                  onChange={(e: any) => {
                                    onChangePolicyPremium(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12">{policyPremiumError}</span>
                              </div>

                              <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${carrierNameError.length > 0 ? " validation-div" : ""}`}>
                                <span>Carrier Name</span>
                                <span className="text-danger"> *</span>
                                {carrierNameLoading ? (
                                  <div className="d-flex justify-content-center align-items-center p-1">
                                    <AtkLoader></AtkLoader>{" "}
                                  </div>
                                ) : (
                                  <select
                                    style={{
                                      width: "102%",
                                      color: "#5c5a5a",
                                      fontWeight: "500",
                                      height: "32px",
                                    }}
                                    className="input-field"
                                    value={carrierName}
                                    onChange={(e: any) => {
                                      onChangeCarrierName(e.target.value);
                                    }}
                                  >
                                    <option value="" selected>
                                      Select an option
                                    </option>

                                    {allCarrierNames.map((name: any) => (
                                      <option key={name} value={name} title={name}>
                                        {name.length > 16 ? name.substring(0, 16) + "..." : name}
                                      </option>
                                    ))}
                                  </select>
                                )}
                                <span className="text-danger text-12">{carrierNameError}</span>
                              </div>

                              <div
                                className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${lineOfBusinessError.length > 0 ? " validation-div" : ""}`}
                              >
                                <span>Line of Business</span>
                                <span className="text-danger"> *</span>
                                <TextField
                                  className="input-field"
                                  type="text"
                                  value={lineOfBusiness}
                                  placeholder="Enter line of business"
                                  onChange={(e: any) => {
                                    onChangeLineOfBusiness(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12">{lineOfBusinessError}</span>
                              </div>

                              <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${quoteNumberError.length > 0 ? " validation-div" : ""}`}>
                                <span>Quote Number</span>
                                {/* <span className="text-danger"> *</span> */}
                                <TextField
                                  className="input-field"
                                  type="text"
                                  value={quoteNumber}
                                  placeholder="Enter quote number"
                                  onChange={(e: any) => {
                                    onChangeQuoteNumber(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12">{quoteNumberError}</span>
                              </div>

                              <div
                                className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${effectiveDateError.length > 0 ? " validation-div" : ""}`}
                              >
                                <span>Effective Date</span>
                                <span className="text-danger"> *</span>
                                <AtkTextField
                                  className="input-field"
                                  type="date"
                                  id="datepicker"
                                  value={moment(effectiveDate).format("YYYY-MM-DD")}
                                  onChange={(e: any) => {
                                    onChangeEffectiveDate(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12 btisErrorText">{effectiveDateError}</span>
                              </div>

                              <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${expiryDateError.length > 0 ? " validation-div" : ""}`}>
                                <span>Expiration Date</span>
                                <span className="text-danger"> *</span>
                                <AtkTextField
                                  className="input-field"
                                  type="date"
                                  id="datepicker"
                                  value={moment(expiryDate).format("YYYY-MM-DD")}
                                  onChange={(e: any) => {
                                    onChangeExpiryDate(e.target.value);
                                  }}
                                />
                                <span className="text-danger text-12 btisErrorText">{expiryDateError}</span>
                              </div>
                              <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${termError.length > 0 ? " validation-div" : ""}`}>
                                <label>Term</label>
                                {/* <span className="text-danger ">*</span> */}

                                <AtkTextField
                                  id="text"
                                  className="input-field "
                                  type="text"
                                  value={term}
                                  onChange={onTermChange}
                                  placeholder="Enter term"
                                />
                                <span className="text-danger text-12">{termError}</span>
                              </div>
                            </div>
                          </>
                        )}

                        {showConfirmBox ? (
                          <div className="row">
                            <div className="col-12 onsubmit-info-show my-2">
                              <SectionMessage appearance="warning">
                                <p>
                                  <span className="d-block text-13">
                                    By clicking{" "}
                                    <span className="text-danger">
                                      <b>Submit</b>
                                    </span>
                                    , a new client/quote will be created in NowCerts. Are you sure you want to proceed?
                                  </span>
                                </p>
                                <div className="text-center mt-1">
                                  {onSubmitLoader ? (
                                    <div className="d-inline-block me-2 ">
                                      <AtkLoader />
                                    </div>
                                  ) : (
                                    <AtkButton
                                      label="OK"
                                      onClick={() => {
                                        onSubmitClick();
                                      }}
                                      className="pf-secondary-btn w-auto me-2"
                                    />
                                  )}
                                  <AtkButton
                                    label="Cancel"
                                    onClick={() => {
                                      oncancelClicks();
                                    }}
                                    className="pf-primary-btn w-auto"
                                  />
                                </div>
                              </SectionMessage>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="row">
                              <div className="col-12 d-flex align-items-center  justify-content-end my-2">
                                {hideSubmitButton ? <AtkButton label="Submit" onClick={OnSubmit} className="pf-secondary-btn w-auto me-2" /> : null}
                              </div>
                            </div>
                          </>
                        )}

                        <div className="row">
                          <div className="col-12 d-flex align-items-center  justify-content-end my-2">
                            {ncURL != "" && ncURL != null ? (
                              <AtkButton
                                label={props.createInsured == true ? "Open Insured" : "Open Prospect"}
                                onClick={onOpenClick}
                                className="pf-secondary-btn w-auto me-2 "
                                // style={{ marginTop: "7px" }}
                              />
                            ) : null}
                            {showUploadAgain ? (
                              <>
                                <AtkButtonIconBefore
                                  label="Upload Again"
                                  onClick={uploadAgain}
                                  className="pf-secondary-btn w-auto me-2 "
                                  style={{ marginTop: "7px" }}
                                />

                                <AtkButtonIconBefore
                                  label="Feedback"
                                  onClick={(e: any) => {
                                    onFeedbackClick(e);
                                  }}
                                  className="pf-secondary-btn w-auto me-2 "
                                  style={{ marginTop: "7px" }}
                                />
                              </>
                            ) : null}

                            <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                              <div className="row mx-auto d-flex align-items-center mb-2">
                                <div className="col-12 feedback-header">
                                  <h4>Feedback</h4>
                                </div>
                                <div className={"form-group col-12 mb-2 text-12"}>
                                  <textarea
                                    name="feedback"
                                    onChange={(e) => onFeedbackChange(e.target.value)}
                                    value={feedBackData.feedback}
                                    className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
                                    rows={4}
                                    placeholder="Enter your feedback here"
                                  />
                                  <span className="text-danger  text-12 mt-1">{feedBackError}</span>
                                </div>
                                <div className="col-4 d-flex justify-content-evenly">
                                  <i
                                    className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
                              ${feedBackData.isLiked == true ? " active" : " "}`}
                                    aria-hidden="true"
                                    onClick={(e) => onThumbsUpClick(feedBackData)}
                                  ></i>
                                  <i
                                    className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                                ${feedBackData.isLiked == false ? " active" : " "}`}
                                    aria-hidden="true"
                                    onClick={(e) => onThumbDownClick(feedBackData)}
                                  ></i>
                                </div>
                                <div className="col-4 text-end p-2 pe-0  ">
                                  <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
                                </div>
                                <div className="col-4 text-end p-2  ">
                                  {feedbackLoading ? (
                                    <div className="d-flex justify-content-center align-items-center">
                                      <AtkLoader />
                                    </div>
                                  ) : (
                                    <AtkButton
                                      label="Submit"
                                      onClick={() => onSubmitFeedbackClick(feedBackData)}
                                      className="pf-secondary-btn w-auto "
                                    />
                                  )}
                                </div>
                              </div>
                            </OverlayPanel>
                          </div>
                        </div>
                        {toggleSelectErrorMsg.length > 0 ? (
                          <div className="d-flex justify-content-center upload-file-error" style={{ width: "100%", padding: "20px 20px 0px" }}>
                            <AtkMessage appearance="error" messageText={toggleSelectErrorMsg} />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
