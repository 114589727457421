import React, { FC, useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CommonValues from "../../common/utils";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import DownloadCSVIcon from "../../assets/images/csv-download.svg";
import DownloadXMLIcon from "../../assets/images/xml-download.svg";
import { saveAs } from "file-saver";
import AtkButton from "../../AtlaskitControls/AtkButton";
import moment from "moment";
import PreviewIcon from "../../assets/images/ViewDetailsIcon.png";
import feedbackImg from "../../assets/images/feedback.svg";
import { Dialog } from "primereact/dialog";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import Header from "../../common/Header";
import CommissionScanSidebar from "../CommissionScan/CommissionScanSidebar";
import CommissionScanMiddleHeader from "../CommissionScan/CommissionScanMiddleHeader";
import SendIcon from "@atlaskit/icon/glyph/send";
import AtkTextField from "../../AtlaskitControls/AtkTextField";

export default function CommissionScanHistory() {
  const toast: any = useRef("");
  const navigate = useNavigate();
  const feedbackRef = useRef<OverlayPanel>(null);
  const [historyloading, setHistoryLoading] = useState(true);
  const [historyData, setHistoryData] = useState<any>([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [csvFileData, setCsvFileData] = useState<any>([]);
  const [previewFileLoading, setPreviewFileLoading] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [columnKeys, setColumnKeys] = useState<any>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [importCommissionLoading, setImportCommissionLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    getHistoryDetails();
    if (feedbackRef.current) {
      feedbackRef.current.hide();
    }
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const getHistoryDetails = () => {
    setHistoryLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAICommissionStatement/commissionTransactions`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setHistoryData(response.data);
        setHistoryLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting  history details", life: 3000 });
          }
        }
        setHistoryLoading(false);
      });
  };
  const statusTemplate = (rowData: any) => {
    return rowData.status === 0 ? (
      <b className="text-warning"> Submitted</b>
    ) : rowData.status === 1 ? (
      <b className="text-info"> In Progress</b>
    ) : rowData.status === 2 ? (
      <b className="text-success"> Done</b>
    ) : rowData.status === 3 ? (
      <b className="text-danger"> Failed</b>
    ) : null;
  };
  const actionTemplate = (rowData: any) => {
    return rowData.status === 2 ? (
      <>
        <div className="history-action-column">
          <img src={DownloadCSVIcon} onClick={() => onDownloadClick("csv", rowData)} title="Download CSV" className="me-2 show-cursor" />
          <img src={DownloadXMLIcon} onClick={() => onDownloadClick("xml", rowData)} title="Download XML" className="me-2 show-cursor" />
          <img src={PreviewIcon} height={20} onClick={() => onPreviewClick(rowData)} title="Preview" className="me-2 show-cursor" />

          <span onClick={() => onUploadToAMSClicked(rowData.transactionId)} title="Import Commissions" className="show-cursor p-1">
            {importCommissionLoading && rowData.transactionId == transactionId ?
              <AtkLoader />
              :
              <SendIcon label="Import Commissions" />
            }
          </span>
        </div>
      </>
    ) : null;
  };
  const feedbackTemplate = (rowData: any) => {
    return <img src={feedbackImg} className=" show-cursor" onClick={(e) => onShowFeedbackPopup(e, rowData)} title="Feedback" />;
  };
  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };
  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    setHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.transactionId === rowData.transactionId ? { ...response, isLiked: liked } : response))
    );
    onSaveRatings(rowData);
  };
  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
    setHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.transactionId === rowData.transactionId ? { ...response, isLiked: liked } : response))
    );
  };
  const onDownloadClick = (documentType: string, rowData: any) => {
    const transactionId = rowData.transactionId == undefined ? rowData : rowData.transactionId;
    setTransactionId(transactionId);
    const token = CommonValues.GetToken();
    axios({
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAICommissionStatement/document/${documentType}/${transactionId}`,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response: any) => {
        let filename = rowData.fileName == undefined ? fileName : rowData.fileName;
        let fileExtension = filename.substring(filename.lastIndexOf('.'),filename.length)
        // filename = filename.replace(".PDF", ".pdf");
        if (documentType === "csv") {
          saveAs(response.data, filename.replace(fileExtension, ".csv"));
        }
        else if (documentType === "xml") {
          saveAs(response.data, filename.replace(fileExtension, ".xml"));
        }
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting  history details", life: 3000 });
          }
        }
        setHistoryLoading(false);
      });
  };
  const onPreviewClick = (rowData: any) => {
    const transactionId = rowData.transactionId;
    const fileName = rowData.fileName;
    setFileName(fileName);
    setTransactionId(transactionId);
    setShowPopUp(true);
    setPreviewFileLoading(true);
    const token = CommonValues.GetToken();
    var data = {
      transactionId: rowData.transactionId,
      fileName: rowData.fileName,
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAICommissionStatement/previewdocument`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response: any) => {
        setPdfUrl(response.data.pdfUrl);
        
        setCsvFileData(JSON.parse(response.data.csvData));
        setColumnKeys(Object.keys(JSON.parse(response.data.csvData)[0]));
        setPreviewFileLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting  history details", life: 3000 });
          }
        }
        setPreviewFileLoading(false);
      });
  };
  const onHidePopupClicked = () => {
    setShowPopUp(false);
    setPdfUrl("");
    setCsvFileData([]);
  };
  const onShowFeedbackPopup = (e: any, rowData: any) => {
    setFeedBackError("");
    const feedback = { ...rowData, feedback: "" };
    setFeedBackData(feedback);
    feedbackRef.current?.toggle(e);
  };
  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };
  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      setTransactionId(rowData.transactionId);
      onSaveFeedback(rowData);
    }
  };
  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: rowData.transactionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAICommissionStatement/commissionfeedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Feedback Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };
  const onSaveRatings = (rowData: any) => {
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: rowData.transactionId,
      IsLiked: rowData.isLiked,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/NCAICommissionStatement/commissionratings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Ratings Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };
  const onUploadToAMSClicked = (transactionId: any) => {
    setImportCommissionLoading(true);
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_DataFabric_Url}/api/ncaicommissionstatement/upload/${transactionId}?token=${nowCertsToken}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      }
    };

    axios(config)
      .then(function (response) {
        window.open(response.data.redirectURL, "_blank")
        setImportCommissionLoading(false);
      })
      .catch(function (error) {
        setImportCommissionLoading(false);
        console.log(error);
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          setErrorMessage("Error while uploading the file.");
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
        }
      });
  }
  return (
    <>
      <Toast ref={toast} />
      <div className="smart-scan-history-main-page">
        <Header pagename="smart-scan-history" />
        <div className="content-wrapper ">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div  ">
            <div className="col-12">
              <CommissionScanMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <CommissionScanSidebar pagename="commission-scan-history" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">
                  <div className="col-12 mt-2  text-end ">
                    <AtkButton label="Refresh" onClick={getHistoryDetails} className=" pf-primary-btn w-auto" />
                  </div>
                  <div className="col-12  history-table mt-3">
                    <Toast ref={toast} />
                        <div className="mb-3">
                          <span className="p-input-icon-left w-25">
                            <i className="fa fa-search grid-search-icon" />
                            <AtkTextField
                              type="text"
                              id="txtfirstName"
                              name="globalFilter"
                              onChange={(e) => setGlobalFilter(e.target.value)}
                              value={globalFilter}
                              className="input-field"
                              placeholder="Search..."
                            />
                          </span>
                        </div>
                        {historyloading ? (
                      <div className="d-flex align-ietms-center justify-content-center overflow-hidden">
                        <AtkLoader />
                      </div>
                    ) : (
                        <DataTable
                          value={historyData}
                          paginator
                          paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                          rows={10}
                          globalFilter={globalFilter}
                          emptyMessage="No data available."
                        >
                          <Column header="File Name" field="fileName" sortable></Column>
                          <Column
                            header="Submitted Date"
                            body={(rowData) => {
                              const utcdate = moment.utc(rowData.created);
                              var localDate = moment(utcdate).local();
                              return <span className="justify-">{localDate.format("MM-DD-YYYY HH:mm")} </span>;
                            }}
                            field="created"
                            sortable
                            style={{ width: "12%" }}
                          ></Column>
                          <Column header="Status" field="status" body={statusTemplate} style={{ width: "10%" }} sortable></Column>
                          <Column header="Action" body={actionTemplate} style={{ width: "12%" }}></Column>
                          <Column header="Feedback" body={feedbackTemplate} style={{ width: "5%" }}></Column>
                        </DataTable>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog header="" visible={showPopUp} style={{ width: "80vw", height: "90vh" }} onHide={onHidePopupClicked} draggable={false}>
        {previewFileLoading ? (
          <div className="d-flex align-items-center justify-content-center overflow-hidden">
            <AtkLoader />
          </div>
        ) : (
          <div className="row">
            <div className="col-12" style={{ display: "flex", justifyContent: "right"}}>
              <img
                src={DownloadCSVIcon}
                title="Download CSV"
                className="me-2 show-cursor"
                onClick={() => {
                  onDownloadClick("csv", transactionId);
                }}
              />
              <img
                src={DownloadXMLIcon}
                title="Download XML"
                className="me-2 show-cursor"
                onClick={() => {
                  onDownloadClick("xml", transactionId);
                }}
              />
              {importCommissionLoading
                ?
                <AtkLoader />
                :
                <AtkButton label="Import Commissions" onClick={() => onUploadToAMSClicked(transactionId)} className=" pf-primary-btn w-auto" />
              }
            </div>

            <div className="col-6 border-end">
              <b>{fileName.toLowerCase().endsWith(".pdf") ? "Uploaded PDF" : "No preview available for Excel file" }</b>
              <iframe src={`${fileName.toLowerCase().endsWith(".pdf") ? pdfUrl : ""}`} style={{ overflow: "scroll", height: "75vh", width: "100%" }} className="mt-2"></iframe>
            </div>
            <div className="col-6 history-table">
              <b>Extracted data</b>
              {csvFileData.length > 0 ? (
                <div className="mt-2">
                  <DataTable value={csvFileData} paginator paginatorTemplate="PrevPageLink PageLinks NextPageLink" rows={10}>
                    <Column header="Insured" field="Insured"></Column>
                    <Column header="Policy Number" field="Policy_Number"></Column>
                    <Column header="Effective Date" field="Policy_Effective_Date"></Column>
                    <Column header="Transaction Date" field="Transaction_Date"></Column>
                    <Column header="Transaction Type" field="Transaction_Type"></Column>
                    <Column header="Premium" field="Premium"></Column>
                    <Column header="Commission Rate" field="Commission_Rate"></Column>
                    <Column header="Commission Amount" field="Commission_Amount"></Column>
                  </DataTable>
                  {/* <DataTable value={csvFileData} paginator paginatorTemplate="PrevPageLink PageLinks NextPageLink" rows={10}>
                    {columnKeys.map((key: any) => (
                      <Column key={key} field={key} header={key} />
                    ))}
                  </DataTable> */}
                </div>
              ) : null}
            </div>
          </div>
        )}
      </Dialog>
      <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
        <div className="row mx-auto d-flex align-items-center mb-2">
          <div className="col-12 feedback-header">
            <h4>Feedback</h4>
          </div>
          <div className={"form-group col-12 mb-2 text-12"}>
            <textarea
              name="feedback"
              onChange={(e) => onFeedbackChange(e.target.value)}
              value={feedBackData.feedback}
              className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
              rows={4}
              placeholder="Enter your feedback here"
            />
            <span className="text-danger  text-12 mt-1">{feedBackError}</span>
          </div>
          <div className="col-4 d-flex justify-content-evenly">
            <i
              className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
               ${feedBackData.isLiked == true ? " active" : " "}`}
              aria-hidden="true"
              onClick={(e) => onThumbsUpClick(feedBackData)}
            ></i>
            <i
              className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                ${feedBackData.isLiked == false ? " active" : " "}`}
              aria-hidden="true"
              onClick={(e) => onThumbDownClick(feedBackData)}
            ></i>
          </div>
          <div className="col-4 text-end p-2 pe-0  ">
            <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
          </div>
          <div className="col-4 text-end p-2  ">
            {transactionId == feedBackData.transactionId && feedbackLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <AtkLoader />
              </div>
            ) : (
              <AtkButton label="Submit" onClick={() => onSubmitFeedbackClick(feedBackData)} className="pf-secondary-btn w-auto " />
            )}
          </div>
        </div>
      </OverlayPanel>
      {/* <Footer/> */}
    </>
  );
}
