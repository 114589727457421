import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import Header from '../common/Header';
import FinchMiddleHeader from './FinchMiddleHeader';
import FinchSideBar from './FinchSideBar';
import AtkLoader from '../AtlaskitControls/AtkLoader';
import CommonValues from '../common/utils';
import axios from "axios";
import AtkTextField from '../AtlaskitControls/AtkTextField';
import AtkButtonIconBefore from '../AtlaskitControls/AtkButtonIconBefore';
import copyImg from "../assets/images/copy.png";

export default function FinchLandingPage() {
    const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
    const [currentBalance, setCurrentBalance] = useState("0");
    const [clientName, setClientName] = useState("");
    const [clientNameError, setClientNameError] = useState("");
    const [search, setSearch] = useState("");
    const [searchClientLoading, setSearchClientLoading] = useState(true);
    const [searchClientList, setSearchClientList] = useState<any>([]);
    const [insuredId, setInsuredId] = useState("");
    const [insuredName, setInsuredName] = useState("");
    const [insuredEmail, setInsuredEmail] = useState("");
    const [redirectUrl, setRedirectUrl] = useState("");
    const [newSessionLoading, setNewSessionLoading] = useState(false);

    const navigate = useNavigate();
    const toast: any = useRef("");
    const location = useLocation();
    const navigatedFromChatBot = location.state?.NAVIGATED_FROM_CHAT_BOT || false;

    useEffect(() => {
        const token = CommonValues.GetToken();
        if (token == "" || token == null) {
            CommonValues.Logout(navigate);
        }
        getCardDetails();
    }, []);

    useEffect(() => {
        if (search == "") {
            return;
        }
        setSearchClientLoading(true);
        if (CommonValues.GetTenantId() == process.env.REACT_APP_NowCerts_Tenant_Id) {
            const searchTimeout = setTimeout(() => {
                getNowCertsClients(search);
            }, 10);
            return () => clearTimeout(searchTimeout);
        }
    }, [search]);

    const getNowCertsClients = (query: string) => {
        var data = {
            SearchValue: query,
        };
        let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
        var config = {
            method: "GET",
            url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlist?searchText=${query}&nowCertsToken=${nowCertsToken}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then((response) => {
                setSearchClientList(response.data);
                setSearchClientLoading(false);
            })
            .catch((error) => {
                let errorMessage = "";
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({
                            severity: "error",
                            detail: "Unknown error while loading the insureds data.Please try again later.",
                            life: 3000,
                        });
                    }
                }
                // setErrorMessage(errorMessage);
                setSearchClientLoading(false);
            });
    };
    const getCardDetails = () => {
        setCurrentBalanceLoading(true);
        const token = CommonValues.GetToken();
        const config = {
            method: "get",
            url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        axios(config)
            .then((response) => {
                if (response.data.success) {
                    setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
                }
                setCurrentBalanceLoading(false);
            })
            .catch((error) => {
                setCurrentBalanceLoading(false);
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({
                            severity: "error",
                            detail: "Unknown error while getting current balance",
                            life: 3000,
                        });
                    }
                } else {
                    toast.current.show({
                        severity: "error",
                        detail: "Unknown error while getting current balance",
                        life: 3000,
                    });
                }
            });
    };
    const onClientSearchChange = (value: any) => {
        if (value.length > 255) {
            return;
        }
        setSearch(value);
        setClientName(value);
    };
    const onClientSelect = (client: any) => {
        setSearch("");
        setClientName(client.name);
        setClientNameError("");
        getNowCertsClientsById(client.databaseId);
    };
    const getNowCertsClientsById = (clientId: any) => {
        let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";

        var config = {
            method: "GET",
            url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/customerlistbyId?insuredDatabaseId=${clientId}&nowCertsToken=${nowCertsToken}`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
                "Content-Type": "application/json",
            },
        };
        axios(config)
            .then((response: any) => {
                let insuredInfo = response.data;
                if (insuredInfo != null && insuredInfo.length > 0) {
                    setInsuredId(insuredInfo[0].databaseId);
                    setInsuredName(insuredInfo[0].commercialName);
                    setInsuredEmail(insuredInfo[0].eMail);
                }
            })
            .catch((error: any) => {
                console.log(error);
                if (error.response != null) {
                    if (error.response.status === 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({
                            severity: "error",
                            detail: "Error while getting insured data.",
                            life: 3000,
                        });
                    }
                }
            });
    };
    const onGenerateLinkClicked = () => {
        setNewSessionLoading(true);
        var token = CommonValues.GetToken();
        var data = JSON.stringify({
            customer_id: insuredId,
            customer_name: insuredName,
            customer_email: insuredEmail
        });
        var config = {
            method: "post",
            url: `${process.env.REACT_APP_DataFabric_Url}/api/finch/newsession`,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            data: data
        };

        axios(config)
            .then((response) => {
                setRedirectUrl(response.data.data.connect_url);
                setNewSessionLoading(false);
            })
            .catch((error) => {
                setNewSessionLoading(false);
                if (error.response != null) {
                    if (error.response.status == 401) {
                        CommonValues.Logout(navigate);
                    } else {
                        toast.current.show({
                            severity: "error",
                            detail: "Unknown error while getting current balance",
                            life: 3000,
                        });
                    }
                } else {
                    toast.current.show({
                        severity: "error",
                        detail: "Unknown error while getting current balance",
                        life: 3000,
                    });
                }
            });
    }
    const CopyToClipBoardClicked = () => {
        navigator.clipboard.writeText(redirectUrl)
            .then(() => {
                toast.current.show({ severity: "success", detail: "Copied", life: 2000 });
            })
    }
    return (
        <>
            <Toast ref={toast} />
            <div className="commission-scan-main-page">
                <Header pagename="commission-scan" NavigatedFromChatBot={navigatedFromChatBot} />
                <div className="content-wrapper">
                    <div className="row  wrapper-main-row mx-auto wrapper-below-div  ">
                        <div className="col-12">
                            <FinchMiddleHeader />
                        </div>
                        <div className="row main-content-row mx-auto">
                            <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                                <FinchSideBar pagename="finch" />
                            </div>
                            <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                                <div className="col-lg-12 col-md-12 col-sm-12 ">
                                    <div className="row client-search-row ">
                                        <div className="col-12 d-flex align-items-center justify-content-end">
                                            <div className={`col-4 position-relative  p-0 `}>
                                                <p className="text-14 fw-bold mb-1 ">Select Client </p>

                                                <div className={`search-bar flex-column `}>
                                                    {/* ${clientNameError.length > 0 ? " validation-div " : " "}`}> */}
                                                    <AtkTextField
                                                        type="text"
                                                        id="client-name"
                                                        className={` ${clientName.length > 0 ? "clientname-placeholder" : ""} search-area w-100 input-field `}
                                                        name="search"
                                                        onChange={(e) => {
                                                            onClientSearchChange(e.target.value);
                                                        }}
                                                        value={search}
                                                        placeholder={clientName.length > 0 ? clientName : "Enter client name"}
                                                    />
                                                </div>
                                                {search.replaceAll(" ", "").length > 0 && (
                                                    <div id="client-search" className=" position-absolute  ">
                                                        {searchClientLoading ? (
                                                            <div className="d-flex justify-content-center my-2 client-loader-div">
                                                                <AtkLoader />
                                                            </div>
                                                        ) : searchClientList.length == 0 ? (
                                                            <div style={{ padding: "5px", background: "white" }}>
                                                                <span style={{ color: "#ddd", marginLeft: "10px" }}>No results</span>
                                                            </div>
                                                        ) : (
                                                            searchClientList.map((item: any) => (
                                                                <div
                                                                    style={{ cursor: "pointer", width: "100%" }}
                                                                    onClick={() => {
                                                                        onClientSelect(item);
                                                                    }}
                                                                >
                                                                    <a
                                                                        href="#"
                                                                        style={{
                                                                            color: "black",
                                                                            width: "100%",
                                                                            fontSize: "12px",
                                                                        }}
                                                                    >
                                                                        {item.name}
                                                                    </a>
                                                                </div>
                                                            ))
                                                        )}
                                                    </div>
                                                )}

                                            </div>
                                            <div className="row w-100 d-flex align-items-center justify-content-end">
                                                <div className="col-3 text-center balance-span">
                                                    <label>Current Balance</label>
                                                    {currentBalanceLoading ? (
                                                        <span className="balance-spinner d-flex  align-items-center justify-content-center ">
                                                            <AtkLoader />
                                                        </span>
                                                    ) : (
                                                        <span className=" d-block w-auto ">${currentBalance}</span>
                                                    )}
                                                    <small className="d-block w-auto text-danger mt-0">25 cent will be charged per usage</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3 mx-auto text-12">
                                    <div className="form-group col-lg-9 col-md-10 col-sm-12 mb-2">
                                        <label>Redirect URL</label>
                                        <AtkTextField className="input-field" type="text" id="text" value={redirectUrl} placeholder="" />
                                    </div>
                                    <div className="form-group col-lg-1 col-md-2 col-sm-12 mb-2">
                                        <img src={copyImg} onClick={CopyToClipBoardClicked} className="copy-icon mt-4" alt="" style={{ cursor: "pointer" }} />
                                    </div>
                                    <div className="form-group col-lg-2 col-md-2 col-sm-12 mb-2">
                                        {newSessionLoading ?
                                            <AtkLoader />
                                            :
                                            <AtkButtonIconBefore label="Generate Link" className="pf-secondary-btn mt-3 float-end" onClick={onGenerateLinkClicked} />
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
