import React from 'react'
import { useNavigate } from "react-router-dom";
import NewIcon from "../assets/images/newquote.png";

export default function FinchSideBar(props: any) {
  const navigate = useNavigate();
  
    const onCommissionScanClick = () => {
      navigate("/finch");
    };
  return (
    <div className="pt-4 pf-sidebar-block p-0">
      <>
        <span
          className={props.pagename == "finch" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onCommissionScanClick}
        >
          <img src={NewIcon} className={props.pagename == "finch" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title">New Finch Request</span>{" "}
        </span>

        {/* <span
          className={props.pagename == "commission-scan-history" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onCommissionScanHistoryClick}
        >
          <img src={HistoryIcon} className={props.pagename == "commission-scan-history" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title">History</span>{" "}
        </span> */}
      </>
    </div>
  )
}
