import AtkTextField from "../../AtlaskitControls/AtkTextField"
import AtkButton from "../../AtlaskitControls/AtkButton"
import { Dropdown } from "primereact/dropdown";
export default function AddNewInsuredAndProperty(props: any) {

    const onGetHomeOwnersDataClicked = () => {
        props.onGetHomeOwnersDataClicked();
    }

    const Allstate = [
        { label: "Select state", value: "" },
        { label: "Alaska", value: "AK" },
        { label: "Alabama", value: "AL" },
        { label: "Arkansas", value: "AR" },
        { label: "Arizona", value: "AZ" },
        { label: "California", value: "CA" },
        { label: "Colorado", value: "CO" },
        { label: "Connecticut", value: "CT" },
        { label: "District of Columbia", value: "DC" },
        { label: "Delaware", value: "DE" },
        { label: "Florida", value: "FL" },
        { label: "Georgia", value: "GA" },
        { label: "Hawaii", value: "HI" },
        { label: "Iowa", value: "IA" },
        { label: "Idaho", value: "ID" },
        { label: "Illinois", value: "IL" },
        { label: "Indiana", value: "IN" },
        { label: "Kansas", value: "KS" },
        { label: "Kentucky", value: "KY" },
        { label: "Louisiana", value: "LA" },
        { label: "Massachusetts", value: "MA" },
        { label: "Maryland", value: "MD" },
        { label: "Maine", value: "ME" },
        { label: "Michigan", value: "MI" },
        { label: "Minnesota", value: "MN" },
        { label: "Missouri", value: "MO" },
        { label: "Mississippi", value: "MS" },
        { label: "Montana", value: "MT" },
        { label: "North Carolina", value: "NC" },
        { label: "North Dakota", value: "ND" },
        { label: "Nebraska", value: "NE" },
        { label: "New Hampshire", value: "NH" },
        { label: "New Jersey", value: "NJ" },
        { label: "New Mexico", value: "NM" },
        { label: "Nevada", value: "NV" },
        { label: "New York", value: "NY" },
        { label: "Ohio", value: "OH" },
        { label: "Oklahoma", value: "OK" },
        { label: "Oregon", value: "OR" },
        { label: "Pennsylvania", value: "PA" },
        { label: "Rhode Island", value: "RI" },
        { label: "South Carolina", value: "SC" },
        { label: "South Dakota", value: "SD" },
        { label: "Tennessee", value: "TN" },
        { label: "Texas", value: "TX" },
        { label: "Utah", value: "UT" },
        { label: "Virginia", value: "VA" },
        { label: "Vermont", value: "VT" },
        { label: "Washington", value: "WA" },
        { label: "Wisconsin", value: "WI" },
        { label: "West Virginia", value: "WV" },
        { label: "Wyoming", value: "WY" },
      ];

    return (
        <>
            <div className="row   d-flex  align-items-end text-12 position-relative">
                <div className="col-12 p-0">
                    <div className="row mt-3 mx-auto text-12">
                        {props.isNewClientSelected == true ?
                            <>
                                <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.clientNameError.length > 0 ? "validation-div" : ""}`}>
                                    <label className="fw-bold mb-1">Client Name</label>
                                    <span className="text-danger "> *</span>
                                    <AtkTextField className="input-field" type="text" id="text" value={props.clientName} onChange={(e: any) => { props.onChangesClientName(e.target.value) }} placeholder="Enter client name" />
                                </div>
                            </> : null}
                        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.addressErrorMessage.length > 0 ? "validation-div" : ""}`}>
                            <label className="fw-bold mb-1">Address Line 1</label>
                            <span className="text-danger "> *</span>
                            <AtkTextField
                                className="input-field"
                                type="text"
                                id="text"
                                value={props.addressLine1}
                                placeholder="Enter address line1"
                                onChange={(e: any) => { props.onChangesAddress(e.target.value) }}
                            />
                            <span className="text-12 text-danger">{props.addressErrorMessage}</span>
                        </div>
                        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.cityErrorMessage.length > 0 ? "validation-div" : ""}`}>
                            <label className="fw-bold mb-1">City</label>
                            <span className="text-danger "> *</span>
                            <AtkTextField
                                className="input-field"
                                type="text"
                                id="text"
                                placeholder="Enter city"
                                value={props.city}
                                onChange={(e: any) => { props.onChangesCity(e.target.value) }}
                            />
                            <span className="text-12 text-danger">{props.cityErrorMessage}</span>
                        </div>
                        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.stateErrorMessage.length > 0 ? "validation-div" : ""}`}>
                            <label className="fw-bold mb-1">State</label>
                            <span className="text-danger "> *</span>
                            <Dropdown
                                type="text"
                                id="text"
                                className="finance-company-dropdown input-field"
                                value={props.state}
                                onChange={(e: any) => { props.stateDropdownClicked(e.target.value) }}
                                options={Allstate}
                            />
                            <span className="text-12 text-danger">{props.stateErrorMessage}</span>
                        </div>
                        <div className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.zipCodeErrorMessage.length > 0 ? "validation-div" : ""}`}>
                            <label className="fw-bold mb-1">Zip Code</label>
                            <span className="text-danger "> *</span>
                            <AtkTextField
                                className="input-field"
                                type="text"
                                id="text"
                                placeholder="Enter zip Code"
                                value={props.zipCode}
                                onChange={(e: any) => { props.onChangesZipCode(e.target.value) }}
                            />
                            <span className="text-12 text-danger">{props.zipCodeErrorMessage}</span>
                        </div>
                        <div className="row mt-2">
                            <div className="col-10"></div>
                            <div className="col-2">
                                <AtkButton label="Get Home Owners Data" className="pf-secondary-btn" onClick={onGetHomeOwnersDataClicked} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}