
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column";
import { useEffect, useRef } from "react";
import CommonValues from "../../common/utils";
import { useNavigate } from "react-router-dom";
export default function DriverScheduleDetails(props: any) {
  const navigate= useNavigate();
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
  }, []);

  const actionLicenseNumber=(rowData:any)=>{
    let licenseNumber=props.fromHistoryPage == true ?rowData.LicenseNumber:rowData.licenseNumber;
    if(licenseNumber!="" && licenseNumber !=null){
      return(
        <span className=" text-12">
          {licenseNumber}
      </span>
      )
    }else{
      return(
      <span className=" text-12">
      NA
      </span>
      )
    }
  }
  const actionDOB=(rowData:any)=>{
    let dob=props.fromHistoryPage == true ?rowData.DOB:rowData.dob;
    if( dob !=null && dob !=""){
      return(
        <span className=" text-12">
          {dob}
      </span>
      )
    }else{
      return(
      <span className=" text-12">
      NA
      </span>
      )
    }
  }
  return (
    <>
      <div className="row">
        <div className="col-12  history-table p-3 rounded-bottom">
          <DataTable
            value={props.driverScheduleResponse}
            paginator
            paginatorTemplate="PrevPageLink PageLinks NextPageLink"
            rows={8}
            selectionMode="single"
             emptyMessage="No data available."
          >
            <Column header="License Number" field="licenseNumber" body={actionLicenseNumber} sortable></Column>
            <Column header="DOB" field="dob" body={actionDOB}  sortable ></Column>
            <Column header="Name" field={props.fromHistoryPage == true ? "Name":"name"} sortable ></Column>
            <Column header="Excluded" field={props.fromHistoryPage == true ?"Excluded":"excluded"} sortable></Column>
          </DataTable>
        </div>
      </div>
    </>
  )
}