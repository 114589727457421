
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkMessage from "../../AtlaskitControls/AtkMessage";


export default function HomeOwnersMainPage(props: any) {
  const onSubmitClick = () => {
    props.onSubmitClick();
  }

  return (
    <>

      {props.showResponse ? (
        <div className="row mt-3 mx-auto text-12">
          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Construction Type</label>

            <AtkTextField className="input-field" type="text" id="text" value={props.constructionType} onChange={(e) => { props.onChangeConstructionType(e.target.value) }} placeholder="Enter name" />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Occupancy</label>
            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props.occupancy}
              onChange={(e) => { props.onChangeOccupancy(e.target.value) }}
              placeholder="Enter construction type"
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Residence Type</label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props.residenceType}
              onChange={(e) => { props.onChangeResidenceType(e.target.value) }}
              placeholder="Enter residence type"
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Usage Type</label>
            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props.usageType}
              onChange={(e) => { props.onChangeUsageType(e.target.value) }}
              placeholder="Enter usage type"
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Year Build</label>

            <AtkTextField className="input-field" type="text" id="text" value={props.yearBuilt} onChange={(e) => { props.onChangeYearBuild(e.target.value) }} placeholder="Enter name" />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Room</label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props.room}
              onChange={(e) => { props.onChangeRoomCount(e.target.value) }}
              placeholder="Enter total room count"
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Families</label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props.families}
              onChange={(e) => { props.onChangeFamilies(e.target.value) }}
              placeholder="Enter total families room count"
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Market Value ($)</label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props.marketValue}
              onChange={(e) => { props.onChangeMarketValue(e.target.value) }}
              placeholder="Enter current market value"
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Replacement Cost ($)</label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props.replacementCost}
              onChange={(e) => { props.onChangeReplacementCost(e.target.value) }}
              placeholder="Enter replacement value"
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Total Living Area</label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props.totalLivingArea}
              onChange={(e) => { props.onChangeTotalLivingArea(e.target.value) }}
              placeholder="Enter total living area"
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Fire Places</label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props.firePlaces}
              onChange={(e) => { props.onChangeFirePlaces(e.target.value) }}
              placeholder="Enter types of fire places"
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Rating </label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props.rating}
              onChange={(e) => { props.onChangeRating(e.target.value) }}
              placeholder="Enter rate area code"
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Roof Material</label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props.roofMaterial}
              onChange={(e) => { props.onChangeRoofMaterial(e.target.value) }}
              placeholder="Enter roof material type"
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Primary Heat</label>
            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props.primaryHeat}
              onChange={(e) => { props.onChangePrimaryHeat(e.target.value) }}
              placeholder="Enter primary heat"
            />
          </div>
          <div className="col-12 text-end ">
            {props.onSubmitLoading ? <AtkLoader /> : <AtkButtonIconBefore label="Submit" className="pf-secondary-btn" onClick={onSubmitClick} />}
          </div>
        </div>
      ) :
        <div className="row mt-5">
          <div className="col-4"></div>
          <div className="col-4">
            <AtkMessage messageText="No data found!" />
          </div>
        </div>
      }
    </>
  );
}
