import React from 'react'
import ValchoiceLogo from "../../assets/images/valchoice.webp";

export default function ValchoiceMiddleHeader() {
  return (
    <div className="row middle-header-row ps-3">
      <div className="col-12">
        <div className="coi-scan-middle-header-col active ms-2">
          <img src={ValchoiceLogo} className="landing-page-img" />
          <span className="middle-header-title">Carrier Rating Service</span>
        </div>
      </div>{" "}
    </div>
  )
}
