import Toggle from "@atlaskit/toggle";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import axios from "axios";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate, useParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkButtonIconBefore from "../AtlaskitControls/AtkButtonIconBefore";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import Header from "../common/Header";
import CommonValues from "../common/utils";
import PolicyComparisonMiddleHeader from "./PolicyComparisonMiddleHeader";
import PolicyComparisonSidebar from "./PolicyComparisonSidebar";

export default function PolicyComparison() {
  const toast: any = useRef("");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [currentBalance, setCurrentBalance] = useState("0");
  const [insufficientBalance, setInsufficientBalance] = useState(true);
  const [firstSelectedFile, setFirstSelectedFile] = useState<File>(
    new File([], "")
  );
  const [secondSelectedFile, setSecondSelectedFile] = useState<File>(
    new File([], "")
  );
  const [fileName1, setFileName1] = useState("");
  const [fileName2, setFileName2] = useState("");
  const [firstFileFormatErrorMessage, setFirstFileFormatErrorMessage] =
    useState("");
  const [secondFileFormatErrorMessage, setSecondFileFormatErrorMessage] =
    useState("");
  const [comparedResponse, setComparedResponse] = useState(``);
  const [summarizeLoading, setSummarizeLoading] = useState(false);
  const [generateProposalLoading, setGenerateProposalLoading] = useState(false);
  const [comparePoliciesLoading, setComparePoliciesLoading] = useState(false);
  const [compareLimitsLoading, setCompareLimitsLoading] = useState(false);
  const [compareCoverageCodesLoading, setCompareCoverageCodesLoading] =
    useState(false);
  const [summarizeSelected, setSummarizeSelected] = useState(false);
  const [comparePoliciesSelected, setComparePoliciesSelected] = useState(false);
  const [summarizePolicyButtonSelected, setSummarizePolicyButtonSelected] =
    useState(false);
  const [generateProposalButtonSelected, setGenerateProposalButtonSelected] =
    useState(false);
  const [comparePoliciesButtonSelected, setComparePoliciesButtonSelected] =
    useState(false);
  const [compareLimitsButtonSelected, setCompareLimitsButtonSelected] =
    useState(false);
  const [compareCoveragesButtonSelected, setCompareCoveragesButtonSelected] =
    useState(false);
  const [responseStreaming, setResponseStreaming] = useState(false);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [feedbackId, setFeedbackId] = useState("");
  const { index } = useParams<any>();
  const ctrl = useRef<AbortController | null>(null);

  const navigate = useNavigate();
  const chatContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    getCardDetails();
    if (index == "summary") {
      setSummarizeSelected(true);
      setComparePoliciesSelected(false);
    } else {
      setComparePoliciesSelected(true);
      setSummarizeSelected(false);
    }
  }, []);

  const getCardDetails = () => {
    setCurrentBalanceLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success) {
          setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
          setInsufficientBalance(response.data.currentBalance / 100 <= 0);
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };

  const onFirstFileSelect = (e: any) => {
    setFirstFileFormatErrorMessage("");
    if (e.target?.files[0] && e.target.files[0].type === "application/pdf") {
      setFirstSelectedFile(e.target.files[0]);
      setFileName1(e.target.files[0].name);
    } else {
      setFirstFileFormatErrorMessage("Please select pdf files.");
    }
    // e.preventDefault();
  };
  const onSecondFileSelect = (e: any) => {
    setSecondFileFormatErrorMessage("");
    if (e.target?.files[0] && e.target.files[0].type === "application/pdf") {
      setSecondSelectedFile(e.target.files[0]);
      setFileName2(e.target.files[0].name);
    } else {
      setSecondFileFormatErrorMessage("Please select pdf files.");
    }
    // e.preventDefault();
  };
  const onSummarizePolicyClick = () => {
    setSummarizeLoading(true);
    setSummarizePolicyButtonSelected(true);
    setGenerateProposalButtonSelected(false);
    setComparePoliciesButtonSelected(false);
    setCompareLimitsButtonSelected(false);
    setCompareCoveragesButtonSelected(false);
    onCompareFileClick(1);
  };
  const onGenerateProposalClick = () => {
    setGenerateProposalLoading(true);
    setSummarizePolicyButtonSelected(false);
    setGenerateProposalButtonSelected(true);
    setComparePoliciesButtonSelected(false);
    setCompareLimitsButtonSelected(false);
    setCompareCoveragesButtonSelected(false);
    onCompareFileClick(5);
  };
  const onCompareLimitsClick = () => {
    setCompareLimitsLoading(true);
    setSummarizePolicyButtonSelected(false);
    setGenerateProposalButtonSelected(false);
    setComparePoliciesButtonSelected(false);
    setCompareLimitsButtonSelected(true);
    setCompareCoveragesButtonSelected(false);
    onCompareFileClick(3);
  };
  const onCompareCoverageCodesClick = () => {
    setCompareCoverageCodesLoading(true);
    setSummarizePolicyButtonSelected(false);
    setGenerateProposalButtonSelected(false);
    setComparePoliciesButtonSelected(false);
    setCompareLimitsButtonSelected(false);
    setCompareCoveragesButtonSelected(true);
    onCompareFileClick(4);
  };
  const onComparePoliciesClick = () => {
    setComparePoliciesLoading(true);
    setSummarizePolicyButtonSelected(false);
    setGenerateProposalButtonSelected(false);
    setComparePoliciesButtonSelected(true);
    setCompareLimitsButtonSelected(false);
    setCompareCoveragesButtonSelected(false);
    onCompareFileClick(2);
  };
  const onCloseIconClick = (fileName: string, fileSlot: number) => {
    if (fileSlot == 1 && fileName === fileName1) {
      setFirstSelectedFile(new File([], ""));
      setFileName1("");
    } else if (fileSlot == 2 && fileName === fileName2) {
      setSecondSelectedFile(new File([], ""));
      setFileName2("");
    }
  };
  const onCompareFileClick = async (prompt: number) => {
    if (!validatePolicyCompareRequest(prompt)) {
      return;
    }
    let question = getPolicyComparePrompt(prompt);
    let action = getPolicyCompareAction(prompt);
    const FormData = require("form-data");
    const data = new FormData();
    data.append("FirstFormFile", firstSelectedFile);
    data.append("SecondFormFile", secondSelectedFile);
    data.append("Question", question);
    data.append("Action", action);
    const token = CommonValues.GetToken();
    setComparedResponse("");
    if (ctrl.current) {
      ctrl.current.abort();
    }

    ctrl.current = new AbortController();
    try {
      await fetchEventSource(
        `${process.env.REACT_APP_PolicyAgent_Url}/${
          prompt === 1 || prompt === 5 ? "process_summarize" : "process_files"
        }`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: data,
          signal: ctrl.current.signal,
          onopen(response: any): Promise<void> {
            if (response) {
              if (
                response.ok &&
                response.headers.get("content-type") === "text/event-stream"
              ) {
              } else if (response.status === 401) {
                CommonValues.Logout(navigate);
              } else {
                if (response.status === 403) {
                  toast.current.show({
                    severity: "info",
                    detail: "User does not have enough credits",
                    life: 5000,
                  });
                }
                setResponseStreaming(false);
                setSummarizeLoading(false);
                setGenerateProposalLoading(false);
                setComparePoliciesLoading(false);
                setCompareLimitsLoading(false);
                setCompareCoverageCodesLoading(false);
                // setSummarizePolicyButtonSelected(false);
                // setGenerateProposalButtonSelected(false);
                // setComparePoliciesButtonSelected(false);
                // setCompareLimitsButtonSelected(false);
                // setCompareCoveragesButtonSelected(false);
              }
            }
            return Promise.resolve();
          },
          onmessage(event: any) {
            const parsedData = JSON.parse(event.data);
            const { response, transaction_id } = parsedData;
            if (transaction_id) {
              setFeedbackId(transaction_id);
            }

            if (response) {
              setResponseStreaming(true);
              setSummarizeLoading(false);
              setGenerateProposalLoading(false);
              setComparePoliciesLoading(false);
              setCompareLimitsLoading(false);
              setCompareCoverageCodesLoading(false);
              setComparedResponse((previous) => previous + response);
            } else {
              setComparedResponse((previous) => `${previous}\n`);
            }

            scrollToBottomForAnswer();
          },
          onclose() {
            console.log("Connection closed by the server");
            setResponseStreaming(false);
            getCardDetails();
          },
          onerror(error: any) {
            setResponseStreaming(false);
            setSummarizeLoading(false);
            setGenerateProposalLoading(false);
            setComparePoliciesLoading(false);
            setCompareLimitsLoading(false);
            setCompareCoverageCodesLoading(false);
          },
        }
      );
    } catch (error: any) {}
  };
  const getPolicyComparePrompt = (prompt: number) => {
    let question = "";
    if (prompt === 1) {
      question = `Provide a detailed summary of ${firstSelectedFile.name}. Extract all the fields with their values, including limits, coverages, and premium, Provide brief summary about these details and the document in bullet list and then provide extracted details in single tabular format. At the end provide summary of policy terms and condition and endorsement details if any.`;
    } else if (prompt === 2) {
      question = `You are a smart insurance quote comparer. Your task is to analyze ${firstSelectedFile.name} and ${secondSelectedFile.name} and provide detailed comparison including limits, coverages and premium in a tabular format. Additionally, offer a brief analysis on which policy might be more suitable for someone prioritizing immediate coverage for pre-existing conditions versus cost savings.`;
    } else if (prompt === 3) {
      question = `You are a smart insurance quote comparer. Your task is to analyze ${firstSelectedFile.name} and ${secondSelectedFile.name} for limits and provide a comparison of limits in a tabular format.`;
    } else if (prompt === 4) {
      question = `You are a smart insurance quote comparer. Your task is to analyze ${firstSelectedFile.name} and ${secondSelectedFile.name} for coverages and provide a comparison of coverages in a tabular format.`;
    } else if (prompt === 5) {
      question = `You are an AI assistant that specializes in generating professional insurance policy proposals.Your input is a quote letter from an insurance company that includes the client details, policy terms, premium amounts, coverage details, exclusions, endorsements, and other relevant information.Goal:    Use the input data to draft a well-structured insurance proposal, clearly outlining the key aspects of the policy in a professional and client-friendly format.Structure of the Proposal:    Title: "Insurance Policy Proposal for [Client Name/Company]"    Introduction:        A brief overview of the insurance company and its commitment to providing tailored insurance solutions.        A sentence expressing gratitude for considering this proposal.    Client Details:        Name/Company Name        Address        Contact Details    Policy Details:        Policy Name/Type        Effective Date and Expiry Date        Premium Amount (including breakdown if applicable)    Coverage Details:        List of coverages and their corresponding limits.        Optional coverages or add-ons (if mentioned).    Exclusions:        Clear bullet points of what the policy does not cover.    Terms and Conditions:        Any specific terms, endorsements, or important clauses.    Benefits:        Key reasons why this policy is valuable to the client.        Highlight unique benefits or services.    Next Steps:        Details about how to proceed with the proposal, such as required documents, payment terms, or contact information.    Closing Statement:        A professional closing note offering support and expressing eagerness to assist further.Tone:    The proposal should be professional, concise, and client-focused. Use clear and formal language.`;
    }
    return question;
  };
  const getPolicyCompareAction = (prompt: number) => {
    let action = "";
    if (prompt === 1) {
      action = `Summarize Policy`;
    } else if (prompt === 2) {
      action = `Compare Policies`;
    } else if (prompt === 3) {
      action = `Compare Limits`;
    } else if (prompt === 4) {
      action = `Compare Coverage Codes`;
    } else if (prompt === 5) {
      action = `Generate Proposal`;
    }
    return action;
  };
  const scrollToBottomForAnswer = () => {
    if (chatContainerRef.current) {
      const lastAnswerElement = document.querySelector(".last-answer-block");
      if (lastAnswerElement) {
        const { offsetTop } = lastAnswerElement as HTMLElement;
        chatContainerRef.current.scrollTop = offsetTop;
      }
    }
  };
  const validatePolicyCompareRequest = (prompt: number): boolean => {
    let isValid: boolean = false;
    if (prompt === 1 && firstSelectedFile.size === 0) {
      setSummarizeLoading(false);
      setSummarizePolicyButtonSelected(false);
      toast.current.show({
        severity: "info",
        detail: "Please select policy to summarize.",
        life: 5000,
      });
    } else if (prompt === 5 && firstSelectedFile.size === 0) {
      setGenerateProposalLoading(false);
      setGenerateProposalButtonSelected(false);
      toast.current.show({
        severity: "info",
        detail: "Please select policy to get general proposal.",
        life: 5000,
      });
    } else if (
      (prompt === 2 || prompt === 3 || prompt === 4) &&
      firstSelectedFile.size === 0 &&
      secondSelectedFile.size === 0
    ) {
      setComparePoliciesLoading(false);
      setCompareLimitsLoading(false);
      setCompareCoverageCodesLoading(false);
      setComparePoliciesButtonSelected(false);
      setCompareLimitsButtonSelected(false);
      setCompareCoveragesButtonSelected(false);
      toast.current.show({
        severity: "info",
        detail: "Please select first and second policy for comparison.",
        life: 5000,
      });
    } else if (
      (prompt === 2 || prompt === 3 || prompt === 4) &&
      firstSelectedFile.size === 0
    ) {
      setComparePoliciesLoading(false);
      setCompareLimitsLoading(false);
      setCompareCoverageCodesLoading(false);
      setComparePoliciesButtonSelected(false);
      setCompareLimitsButtonSelected(false);
      setCompareCoveragesButtonSelected(false);
      toast.current.show({
        severity: "info",
        detail: "Please select first policy for comparison.",
        life: 5000,
      });
    } else if (
      (prompt === 2 || prompt === 3 || prompt === 4) &&
      secondSelectedFile.size === 0
    ) {
      setComparePoliciesLoading(false);
      setCompareLimitsLoading(false);
      setCompareCoverageCodesLoading(false);
      setComparePoliciesButtonSelected(false);
      setCompareLimitsButtonSelected(false);
      setCompareCoveragesButtonSelected(false);
      toast.current.show({
        severity: "info",
        detail: "Please select second policy for comparison.",
        life: 5000,
      });
    } else {
      isValid = true;
    }
    return isValid;
  };
  const onBackButton = () => {
    if (ctrl.current) {
      ctrl.current.abort();
    }
    setFirstSelectedFile(new File([], ""));
    setFileName1("");
    setSecondSelectedFile(new File([], ""));
    setFileName2("");
    setComparedResponse("");
    setSummarizePolicyButtonSelected(false);
    setGenerateProposalButtonSelected(false);
    setComparePoliciesButtonSelected(false);
    setCompareLimitsButtonSelected(false);
    setCompareCoveragesButtonSelected(false);
  };
  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else {
      setFeedBackError("");
      onSaveFeedback(rowData);
      setFeedbackLoading(true);
    }
  };
  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: rowData.transactionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/policycomparisonfeedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({
          severity: "success",
          detail: "Feedback submitted successfully",
          life: 3000,
        });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while submitting feedback",
              life: 3000,
            });
          }
        }
      });
  };
  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };
  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked, transactionId: feedbackId };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
  };
  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked, transactionId: feedbackId };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
  };
  const onSaveRatings = (rowData: any) => {
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: rowData.transactionId,
      IsLiked: rowData.isLiked,
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/policycomparisonratings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({
          severity: "success",
          detail: "Rating submitted successfully",
          life: 3000,
        });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while submitting ratings",
              life: 3000,
            });
          }
        }
      });
  };
  const onFeedbackChange = (newFeedback: string) => {
    const feedback = {
      ...feedBackData,
      feedback: newFeedback,
      transactionId: feedbackId,
    };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };
  const onFeedbackClick = (e: any) => {
    feedbackRef.current?.toggle(e);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="naics-landing-main-page">
        <Header pagename="naics-landing-page" />
        <div className="content-wrapper ">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <PolicyComparisonMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <PolicyComparisonSidebar pagename="policycompare-page" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row history-search-row d-flex  align-items-center justify-content-end text-12 position-relative">
                  <div className="col-2 text-center balance-span">
                    <label>Current Balance</label>
                    {currentBalanceLoading ? (
                      <span className="balance-spinner d-flex  align-items-center justify-content-center ">
                        <AtkLoader />
                      </span>
                    ) : (
                      <span className=" d-block w-auto ">
                        ${currentBalance}
                      </span>
                    )}
                    <small className="d-block w-auto text-danger mt-0">
                      25 cent will be charged per usage
                    </small>
                  </div>
                </div>

                <div className="row mx-auto w-100 ">
                  <div className="col-12 d-flex align-items-center justify-content-center mt-4">
                    {index == "summary" ? (
                      <>
                        <Toggle
                          id="theme"
                          isChecked={summarizeSelected}
                          // onChange={() => {
                          //   setFirstFileFormatErrorMessage("");
                          //   setSecondFileFormatErrorMessage("");
                          //   setSummarizeSelected(!summarizeSelected);
                          //   setComparePoliciesSelected(false);
                          // }}
                          isDisabled={false}
                        />
                        <span className="d-block h5 mx-2 mt-1 me-5">
                          Summarize Policy
                        </span>
                      </>
                    ) : index == "compare" ? (
                      <div className="compare-selection d-flex align-items-center ">
                        <Toggle
                          id="theme"
                          isChecked={comparePoliciesSelected}
                          // onChange={() => {
                          //   setFirstFileFormatErrorMessage("");
                          //   setSecondFileFormatErrorMessage("");
                          //   setSummarizeSelected(false);
                          //   setComparePoliciesSelected(!comparePoliciesSelected);
                          // }}
                          isDisabled={false}
                        />{" "}
                        <span className="d-block h5 mx-2 mt-1">
                          Compare Policies
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row mx-auto w-100 d-flex justify-content-center">
                  <div className="col-2  mt-3"></div>
                  <div
                    className={`col-${
                      comparePoliciesSelected ? "4" : "8"
                    } form-group text-center  mt-3 choose-file-box`}
                  >
                    <div className="file-upload position-relative">
                      <span className="d-block h5">
                        {comparePoliciesSelected ? "First" : ""} Policy
                      </span>

                      <input
                        type="file"
                        className="custom-file-input"
                        id="firstFileInput"
                        onChange={onFirstFileSelect}
                        name="fileData"
                        accept=".pdf"
                      />
                      <label
                        htmlFor="firstFileInput"
                        className="custom-file-label2"
                      >
                        Choose File
                      </label>
                      <p className="text-danger">
                        {firstFileFormatErrorMessage}
                      </p>
                      {fileName1.length > 0 ? (
                        <span className="file-name">
                          {fileName1}{" "}
                          <i
                            className="fa fa-close ms-2 show-cursor "
                            aria-hidden="true"
                            title="Close"
                            onClick={() => onCloseIconClick(fileName1, 1)}
                          ></i>
                        </span>
                      ) : null}
                      <div className="row">
                        <div className="col-2"></div>
                        <div className="col-8  text-12 mt-2">
                          <AtkMessage
                            appearance="information"
                            messageText={
                              "This tool works best with readable PDFs. Please note that scanned or image-based PDFs may not be processed. For scanned documents, consider converting them to a readable format using OCR tools before uploading."
                            }
                          />
                        </div>
                        <div className="col-2"></div>
                      </div>
                    </div>
                  </div>
                  {comparePoliciesSelected && (
                    <div className="col-4 form-group  text-center mt-3 choose-file-box second-choose-file-box">
                      <div className="file-upload">
                        <span className="d-block h5">Second Policy</span>

                        <input
                          type="file"
                          className="custom-file-input"
                          id="secondFileInput"
                          onChange={onSecondFileSelect}
                          name="fileData2"
                          accept=".pdf"
                          // multiple
                        />
                        <label
                          htmlFor="secondFileInput"
                          className="custom-file-label2 "
                        >
                          Choose File
                        </label>
                        <p className="text-danger">
                          {secondFileFormatErrorMessage}
                        </p>
                        {fileName2.length > 0 ? (
                          <span className="file-name ">
                            {fileName2}{" "}
                            <i
                              className="fa fa-close ms-2 show-cursor "
                              aria-hidden="true"
                              title="Close"
                              onClick={() => onCloseIconClick(fileName2, 2)}
                            ></i>
                          </span>
                        ) : null}

                        <div className="row">
                          <div className="col-2"></div>
                          <div className="col-8  text-12 mt-2">
                            <AtkMessage
                              appearance="information"
                              messageText={
                                "This tool works best with readable PDFs. Please note that scanned or image-based PDFs may not be processed. For scanned documents, consider converting them to a readable format using OCR tools before uploading."
                              }
                            />
                          </div>
                          <div className="col-2"></div>
                        </div>
                      </div>
                      {/* <span className="text-danger text-12 ">{fileError}</span> */}
                    </div>
                  )}
                  <div className="col-2 mt-3"></div>
                  <div className="col-2 "></div>

                  <div className="col-8 text-center">
                    <small>
                      This is generated by AI agent, AI agent is still learning
                      and can make mistakes. Check important info.
                    </small>
                  </div>
                  <div className="col-2 "></div>

                  {summarizeSelected && (
                    <>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 upload-btn mt-4 text-center">
                        {summarizeLoading ? (
                          <div className="d-flex justify-content-center">
                            <AtkLoader />
                          </div>
                        ) : summarizePolicyButtonSelected ? (
                          <input
                            className={`btn ${
                              summarizePolicyButtonSelected
                                ? "policy-unselected-btn hide-cursor "
                                : ""
                            }`}
                            type="button"
                            value="Summarize Policy"
                          />
                        ) : (
                          <AtkButton
                            label="Summarize Policy"
                            onClick={onSummarizePolicyClick}
                            className="pf-primary-btn w-auto"
                          />
                        )}
                      </div>
                      <div className="col-md-3 upload-btn mt-4 text-center">
                        {generateProposalLoading ? (
                          <div className="d-flex justify-content-center">
                            <AtkLoader />
                          </div>
                        ) : generateProposalButtonSelected ? (
                          <input
                            className={`btn ${
                              generateProposalButtonSelected
                                ? "policy-unselected-btn hide-cursor"
                                : ""
                            }`}
                            type="button"
                            value="Generate Proposal"
                          />
                        ) : (
                          <AtkButton
                            label="Generate Proposal"
                            onClick={onGenerateProposalClick}
                            className="pf-primary-btn w-auto"
                          />
                        )}
                      </div>
                      <div className="col-md-3"></div>
                    </>
                  )}
                  {comparePoliciesSelected && (
                    <>
                      {/* <div className="col-md-2 mt-4"></div> */}
                      <div className="col-md-8 mt-4">
                        <div className="row mb-2">
                          <div className="col-md-4 text-start p-0 ">
                            {compareLimitsLoading ? (
                              <div className="d-flex justify-content-start">
                                <AtkLoader />
                              </div>
                            ) : compareLimitsButtonSelected ? (
                              <input
                                className={`btn ${
                                  compareLimitsButtonSelected
                                    ? "policy-unselected-btn hide-cursor"
                                    : ""
                                }`}
                                type="button"
                                value="Compare Limits"
                              />
                            ) : (
                              <AtkButton
                                label="Compare Limits"
                                onClick={onCompareLimitsClick}
                                className="pf-primary-btn w-auto"
                              />
                            )}
                          </div>
                          <div className="col-md-4 text-center p-0">
                            {compareCoverageCodesLoading ? (
                              <div className="d-flex justify-content-center">
                                <AtkLoader />
                              </div>
                            ) : compareCoveragesButtonSelected ? (
                              <input
                                className={`btn ${
                                  compareCoveragesButtonSelected
                                    ? "policy-unselected-btn hide-cursor"
                                    : ""
                                }`}
                                type="button"
                                value="Compare Coverage Codes"
                              />
                            ) : (
                              <AtkButton
                                label="Compare Coverage Codes"
                                onClick={onCompareCoverageCodesClick}
                                className="pf-primary-btn w-auto"
                              />
                            )}
                          </div>
                          <div className="col-md-4 text-end p-0">
                            {comparePoliciesLoading ? (
                              <div className="d-flex justify-content-end">
                                <AtkLoader />
                              </div>
                            ) : comparePoliciesButtonSelected ? (
                              <input
                                className={`btn ${
                                  comparePoliciesButtonSelected
                                    ? "policy-unselected-btn hide-cursor"
                                    : ""
                                }`}
                                type="button"
                                value="Compare Policies"
                                style={{ marginRight: "auto" }}
                              />
                            ) : (
                              <AtkButton
                                label="Compare Policies"
                                onClick={onComparePoliciesClick}
                                className="pf-primary-btn w-auto"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {comparedResponse.length > 0 ? (
                  <div className="row last-answer-block">
                    <div className="col-2"></div>
                    <div className="col-8 my-2 compared-policy-response">
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        remarkPlugins={[remarkGfm]}
                        className="answer-block mt-3"
                      >
                        {comparedResponse}
                      </ReactMarkdown>
                      <div>
                        {responseStreaming && (
                          <div className="d-flex justify-content-center my-2">
                            <AtkLoader />
                          </div>
                        )}
                      </div>
                      <div className="text-center">
                        <AtkButton
                          label="Try Again"
                          onClick={onBackButton}
                          className="pf-secondary-btn w-auto mt-2"
                        />
                        <AtkButtonIconBefore
                          label="Feedback"
                          onClick={(e: any) => {
                            onFeedbackClick(e);
                          }}
                          className="pf-secondary-btn w-auto ms-2 mt-2"
                        />
                        {/* <input className="btn btn-outline-info mt-2" type="button" value="Try Again" onClick={onBackButton} /> */}
                      </div>
                    </div>
                    <div className="col-2"></div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
      <OverlayPanel
        ref={feedbackRef}
        className="commission-feedback-panel feedback-panel"
      >
        <div className="row mx-auto d-flex align-items-center mb-2">
          <div className="col-12 feedback-header">
            <h4>Feedback</h4>
          </div>
          <div className={"form-group col-12 mb-2 text-12"}>
            <textarea
              name="feedback"
              onChange={(e) => onFeedbackChange(e.target.value)}
              value={feedBackData.feedback}
              className={`form-control comment-text ${
                feedBackError.length > 0 ? " validation-div " : ""
              }`}
              rows={4}
              placeholder="Enter your feedback here"
            />
            <span className="text-danger  text-12 mt-1">{feedBackError}</span>
          </div>
          <div className="col-4 d-flex justify-content-evenly">
            <i
              className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
                              ${
                                feedBackData.isLiked == true ? " active" : " "
                              }`}
              aria-hidden="true"
              onClick={(e) => onThumbsUpClick(feedBackData)}
            ></i>
            <i
              className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                                ${
                                  feedBackData.isLiked == false
                                    ? " active"
                                    : " "
                                }`}
              aria-hidden="true"
              onClick={(e) => onThumbDownClick(feedBackData)}
            ></i>
          </div>
          <div className="col-4 text-end p-2 pe-0  ">
            <AtkButton
              label="Cancel"
              onClick={onHideFeedbackPopup}
              className="pf-secondary-btn w-auto"
            />
          </div>
          <div className="col-4 text-end p-2  ">
            {feedbackLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <AtkLoader />
              </div>
            ) : (
              <AtkButton
                label="Submit"
                onClick={() => onSubmitFeedbackClick(feedBackData)}
                className="pf-secondary-btn w-auto "
              />
            )}
          </div>
        </div>
      </OverlayPanel>
    </>
  );
}
