import axios from "axios";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import PfdIcon from "../../assets/images/file.png";
import PreviewIcon from "../../assets/images/paper.png";
import RemoveIcon from "../../assets/images/trashcan.png";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import CommonValues from "../../common/utils";
import COIScanMiddleHeader from "./COIScanMiddleHeader";
import COIScanSidebar from "./COIScanSidebar";
import SectionMessage from "@atlaskit/section-message";
import { Skeleton } from "primereact/skeleton";
import AtkButton from "../../AtlaskitControls/AtkButton";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dialog } from "primereact/dialog";

export default function NowCertsCOIScan() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [uploadFile, setUploadFile] = useState<any>([]);
  const [uploadFileLimit, setUploadFileLimit] = useState(1);
  const [successMessage, setSuccessMessage] = useState(false);
  const [filesCount, setFilesCount] = useState(0);
  const [requestFileCount, setRequestFileCount] = useState("");
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [uploadingRetry, setUploadingRetry] = useState(false);
  const [incompleteRequestData, setIncompleteRequestData] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState("");
  const [isfileuploaded, setIsfileuploaded] = useState(false);
  const [fileNameTitle, setFileNameTitle] = useState("");
  const [fileName, setFileName] = useState("");
  const [currentBalance, setCurrentBalance] = useState("0");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);
  const [insufficientBalance, setInsufficientBalance] = useState(true);
  const [submissionId, setSubmissionId] = useState("");
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const location = useLocation();
  const navigatedFromChatBot = location.state?.NAVIGATED_FROM_CHAT_BOT || false;

  useEffect(() => {
    getCurrentBalance();
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const getCurrentBalance = () => {
    setCurrentBalanceLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success == true) {
          setCurrentBalance((response.data.currentBalance / 100).toFixed(2));
          setInsufficientBalance(response.data.currentBalance / 100 <= 0);
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };
  const onDropHandler = (event: any) => {
    event.preventDefault();
    onFileSelectHandler(event.dataTransfer.files);
  };

  const validateSelectedFiles = (files: any) => {
    var regularExpression = /(?:\.([^.]+))?$/;
    var isValid = true;

    for (let index = 0, len = files.length; index < len; index++) {
      const fileExtension: any = regularExpression.exec(files[index].name);
      if (fileExtension === undefined || fileExtension[0] == "") {
        isValid = false;
        break;
      }
      if (fileExtension?.[1].toLowerCase().includes("pdf")) {
        isValid = true;
      } else {
        isValid = false;
        break;
      }
    }
    return isValid;
  };
  const onFileSelectHandler = (files: any) => {
    setFileName("");
    setFileNameTitle("");
    const isValidated = validateSelectedFiles(files);
    if (!isValidated) {
      toast.current.show({
        severity: "error",
        detail: "Please select PDF files only.",
        life: 3000,
      });
      return false;
    }
    let fileList = [];
    let totalSize = 0;
    let currentFiles = uploadFile;
    let filename = files[0].name;
    if (filename.length > 50) {
      let shortenString = filename.substring(0, 50).concat("...");
      setFileName(shortenString);
      setFileNameTitle(filename);
    } else {
      setFileName(filename);
      setFileNameTitle(filename);
    }
    for (let i = 0, len = files.length; i < len; i++) {
      fileList.push({
        file: files[i],
        name: files[i].name,
        size: files[i].size,
      });
      totalSize = totalSize + files[i].size;
    }

    if (fileList.length > 0) {
      if (currentFiles.length > 0) {
        for (var i = 0, l = currentFiles.length; i < l; i++) {
          for (var j = 0; j < fileList.length; j++) {
            if (currentFiles[i].name === fileList[j].name) {
              fileList.splice(j, 1);
            }
          }
        }
      }
      let allFiles = currentFiles.concat(fileList);
      if (allFiles.length > uploadFileLimit) {
        allFiles = allFiles.slice(0, uploadFileLimit);
        toast.current.show({
          severity: "error",
          detail: "Multiple files cannot be uploaded at the same time; please upload a single file.",
          life: 3000,
        });
      }
      setUploadFile(allFiles);
      setFilesCount(allFiles.length);
      setRequestFileCount(allFiles.length);
      setFilesCount(allFiles.length);
    }
  };
  const removeFileFromList = () => {
    setUploadFile([]);
    setIsfileuploaded(false);
    //setSuccessMessage("");
  };
  const createUploadRequest = () => {
    if (uploadFile.length == 0) {
      toast.current.show({
        severity: "error",
        detail: "Please select at least one file to upload.",
        life: 3000,
      });
      return;
    }

    var FormData = require("form-data");
    var data = new FormData();
    data.append("file", uploadFile[0].file);
    const token = CommonValues.GetToken();
    setLoading(true);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/coiscan`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        if (response.data.success) {
          setSubmissionId(response.data.nowcertsCOIScanResponse.transactionId);
          setLoading(false);
          setIsfileuploaded(true);
          setSuccessMessage(true);
          setTimeout(() => {
            setUploadFile([]);
            setIsfileuploaded(false);
          }, 4000);
          getCurrentBalance();
        } else if (!response.data.success && response.data.errorMessage === "User does not have enough credits for NowCerts COI Scan.") {
          setLoading(false);
          toast.current.show({
            severity: "info",
            detail: response.data.errorMessage,
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            detail: "Something went wrong.",
            life: 3000,
          });
          setLoading(false);
          setIsfileuploaded(false);
        }
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          toast.current.show({
            severity: "error",
            detail: "Error while creating the request.",
            life: 3000,
          });
          setLoading(false);
          setIsfileuploaded(false);
        }
      });
  };

  const onHistoryClick = () => {
    navigate("/nowcertcoiscanhistory");
  };
  const onFeedbackClick = (e: any) => {
    feedbackRef.current?.toggle(e);
  };

  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback, submissionId: submissionId };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };
  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked, submissionId: submissionId };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
  };

  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked, submissionId: submissionId };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
  };

  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };

  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else {
      setFeedBackError("");
      onSaveFeedback(rowData);
      setFeedbackLoading(true);
    }
  };

  const onSaveRatings = (rowData: any) => {
    setFeedbackLoading(false);
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: rowData.submissionId,
      Feedback: feedbackVal,
      IsLiked: rowData.isLiked,
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/coiscan/isliked`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Ratings Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };

  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: rowData.submissionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/coiscan/feedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Feedback Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);

        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };

  return (
    <div className="nowcerts-coi-scan-page">
      <Toast ref={toast} />
      <Header NavigatedFromChatBot={navigatedFromChatBot} />
      <div className="content-wrapper">
        <div className="row  wrapper-main-row mx-auto wrapper-below-div  ">
          <div className="col-12">
            <COIScanMiddleHeader />
          </div>
          <div className="row main-content-row mx-auto">
            <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
              <COIScanSidebar pagename="coi-scan" />
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
              <div className="row   d-flex  align-items-end text-12 position-relative">
                <div className="col-lg-12 col-md-12 col-sm-12 ">
                  <div className="row client-search-row ">
                    <div className="col-12 d-flex align-items-center justify-content-end">
                      <div className="row w-100 d-flex align-items-center justify-content-end">
                        <div className="col-2 text-center balance-span">
                          <label>Current Balance</label>
                          {currentBalanceLoading ? (
                            <span className="balance-spinner ">
                              <AtkLoader />
                            </span>
                          ) : (
                            <>
                              <span className=" d-block w-auto ">${currentBalance}</span>
                            </>
                          )}
                          <small className="d-block w-auto text-danger mt-0">25 cent will be charged per usage</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <>
                  {currentBalanceLoading ? (
                    <Skeleton className="uploadNowcertscoi my-3" />
                  ) : (
                    <>
                      {insufficientBalance ? (
                        <div className="uploadNowcertscoi my-3">
                          <AtkMessage appearance="error" messageText="Oop's ! There are not enough funds to complete this transaction." />
                        </div>
                      ) : (
                        <>
                          {successMessage ? (
                            <div className="uploadNowcertscoi my-3">
                              <SectionMessage appearance="success">
                                <h3>File uploaded successfully!</h3>
                                <p className="text-12">
                                  We are processing your file. Please check the{" "}
                                  <b className="show-cursor" style={{ color: "#2dabc1" }} onClick={onHistoryClick}>
                                    History
                                  </b>{" "}
                                  tab to find out the file's current progress status and
                                </p>
                                <p>
                                  feel free to provide your{" "}
                                  <b
                                    className="show-cursor"
                                    style={{ color: "#2dabc1" }}
                                    onClick={(e) => {
                                      onFeedbackClick(e);
                                    }}
                                  >
                                    Feedback here{" "}
                                  </b>
                                </p>
                              </SectionMessage>

                              <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                                <div className="row mx-auto d-flex align-items-center mb-2">
                                  <div className="col-12 feedback-header">
                                    <h4>Feedback</h4>
                                  </div>
                                  <div className={"form-group col-12 mb-2 text-12"}>
                                    <textarea
                                      name="feedback"
                                      onChange={(e) => onFeedbackChange(e.target.value)}
                                      value={feedBackData.feedback}
                                      className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
                                      rows={4}
                                      placeholder="Enter your feedback here"
                                    />
                                    <span className="text-danger  text-12 mt-1">{feedBackError}</span>
                                  </div>
                                  <div className="col-4 d-flex justify-content-evenly">
                                    <i
                                      className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
                              ${feedBackData.isLiked == true ? " active" : " "}`}
                                      aria-hidden="true"
                                      onClick={(e) => onThumbsUpClick(feedBackData)}
                                    ></i>
                                    <i
                                      className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                                ${feedBackData.isLiked == false ? " active" : " "}`}
                                      aria-hidden="true"
                                      onClick={(e) => onThumbDownClick(feedBackData)}
                                    ></i>
                                  </div>
                                  <div className="col-4 text-end p-2 pe-0  ">
                                    <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
                                  </div>
                                  <div className="col-4 text-end p-2  ">
                                    {feedbackLoading ? (
                                      <div className="d-flex justify-content-center align-items-center">
                                        <AtkLoader />
                                      </div>
                                    ) : (
                                      <AtkButton
                                        label="Submit"
                                        onClick={() => onSubmitFeedbackClick(feedBackData)}
                                        className="pf-secondary-btn w-auto "
                                      />
                                    )}
                                  </div>
                                </div>
                              </OverlayPanel>
                            </div>
                          ) : (
                            <div
                              className="uploadNowcertscoi my-3"
                              onDrop={(e) => {
                                onDropHandler(e);
                              }}
                              onDragOver={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <div className="row w-100 ">
                                {uploadFile.length > 0 ? (
                                  <div className="col-12">
                                    {uploadFile.map((file: any, index: any) => (
                                      <div className="show-pdf-icon">
                                        <div className="scan-file-name">
                                          <div className="preview-icon">
                                            <img src={PreviewIcon} />
                                          </div>
                                          <div className="filename">
                                            <div title={fileNameTitle}>{fileName}</div>
                                            <div>{(parseInt(file.size) / 1000).toFixed(0)}KB</div>
                                          </div>
                                        </div>
                                        {uploadComplete ? (
                                          <div>
                                            <span className="uploaded">Uploaded</span>
                                          </div>
                                        ) : (
                                          uploadingFiles == false && (
                                            <div onClick={() => removeFileFromList()} className="show-cursor">
                                              <img src={RemoveIcon} />
                                            </div>
                                          )
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <>
                                    <>
                                      <div className="row" style={{ textAlign: "center" }}>
                                        <div className="col-12">
                                          <img src={PfdIcon} alt="" width="70" />
                                        </div>

                                        <div className="col-12 mt-4">
                                          <div className="col-12">
                                            <span className="drag-and-drop">Drag & Drop file here</span>
                                          </div>
                                          <div className="col-12 my-2">
                                            <span
                                              style={{
                                                color: "black",
                                                fontSize: "large",
                                              }}
                                            >
                                              or
                                            </span>
                                          </div>
                                          <label className="mb-0 show-cursor">
                                            <span className="browse">Browse PDF</span>
                                            <input
                                              type="file"
                                              name="filefield"
                                              accept=".pdf"
                                              id="bulkFileUploader"
                                              style={{
                                                visibility: "hidden",
                                                display: "none",
                                              }}
                                              onChange={(e) => {
                                                onFileSelectHandler(e.target.files);
                                              }}
                                            />
                                          </label>
                                        </div>
                                        <div className="coi-info mt-3">
                                          <span className="text-14 mt-2 info">Supported document type: Certificate Of Insurance (Acord 25)</span>
                                        </div>
                                      </div>
                                    </>
                                  </>
                                )}

                                <div className="col-12 text-center">
                                  {isfileuploaded ? null : (
                                    <>
                                      {uploadingFiles == false &&
                                        incompleteRequestData == false &&
                                        uploadingRetry == false &&
                                        uploadFile.length > 0 && (
                                          <div className="d-flex justify-content-center" style={{ width: "100%", padding: "20px" }}>
                                            {loading == false ? (
                                              <>
                                                <AtkButtonIconBefore
                                                  label="Upload"
                                                  className="pf-secondary-btn"
                                                  onClick={() => {
                                                    createUploadRequest();
                                                  }}
                                                />
                                              </>
                                            ) : (
                                              <div className="d-flex justify-content-center align-items-center p-2">
                                                <AtkLoader></AtkLoader>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                    </>
                                  )}
                                </div>
                                <div className="col-2"></div>
                                <div className="col-8  text-12 mt-3">
                                  <AtkMessage
                                    appearance="information"
                                    messageText={
                                      "This tool works best with readable PDFs. Please note that scanned or image-based PDFs may not be processed. For scanned documents, consider converting them to a readable format using OCR tools before uploading."
                                    }
                                  />
                                </div>
                                <div className="col-2"></div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
