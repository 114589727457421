

import TextField from "@atlaskit/textfield";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import moment from "moment";
import { useEffect, useRef } from "react";
import CommonValues from "../../common/utils";
import { useNavigate } from "react-router-dom";
export default function InsuredDetails(props: any) {
    const navigate = useNavigate();
    useEffect(() => {
        const token = CommonValues.GetToken();
        if (token == "" || token == null) {
            CommonValues.Logout(navigate);
        }
    }, []);
    return (
        <>
            <div className="row  my-2 mx-auto">
                <div className="col-12">
                    <span className="text-14 ">
                        <b>Insured Information</b>
                    </span>
                </div>
            </div>
            <div className="row smartscan-block  mx-auto ">
                <div
                    className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.insuredNameErrorMessage !="" ? "validation-div" : ""}`}
                >
                    <label>Insured Name </label>
                    <span className="text-danger "> *</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.insuredName}
                        placeholder={props.fromHistoryPage == true ? "" : "Enter insured name"}
                        onChange={(e: any) => { props.onChangesInsuredName(e.target.value) }}
                        isDisabled={props.fromHistoryPage == true ? true : false}
                    />
                    <span className="text-12 text-danger">{props.insuredNameErrorMessage}</span>
                </div>
                
                <div
                    className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.addressErrorMessage !="" ? "validation-div" : ""}`}
                >
                    <label>Address </label>
                    <span className="text-danger "> *</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.address}
                        placeholder={props.fromHistoryPage == true ? "" : "Enter address"}
                        onChange={(e: any) => { props.onChangesAddress(e.target.value) }}
                        isDisabled={props.fromHistoryPage == true ? true : false}
                    />
                    <span className="text-12 text-danger">{props.addressErrorMessage}</span>
                </div>
                <div
                    className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.addressErrorMessage !="" ? "validation-div" : ""}`}
                >
                    <label>City </label>
                    <span className="text-danger "> *</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.city}
                        placeholder={props.fromHistoryPage == true ? "" : "Enter city"}
                        onChange={(e: any) => { props.onChangesCity(e.target.value) }}
                        isDisabled={props.fromHistoryPage == true ? true : false}
                    />
                    <span className="text-12 text-danger">{props.cityErrorMessage}</span>
                </div>
                <div
                    className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.stateErrorMessage !="" ? "validation-div" : ""}`}
                >
                    <label>State </label>
                    <span className="text-danger "> *</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.state}
                        placeholder={props.fromHistoryPage == true ? "" : "Enter state"}
                        onChange={(e: any) => { props.onChangesState(e.target.value) }}
                        isDisabled={props.fromHistoryPage == true ? true : false}
                    />
                     <span className="text-12 text-danger">{props.stateErrorMessage}</span>
                </div>
                <div
                    className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.zipCodeErrorMessage !="" ? "validation-div" : ""}`}
                >
                    <label>Zip Code </label>
                    <span className="text-danger "> *</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.zipCode}
                        placeholder={props.fromHistoryPage == true ? "" : "Enter zip code"}
                        onChange={(e: any) => { props.onChangesZipCode(e.target.value) }}
                        isDisabled={props.fromHistoryPage == true ? true : false}
                    />
                    <span className="text-12 text-danger">{props.zipCodeErrorMessage}</span>
                </div>
            </div>

            <div className="row  my-2 mx-auto">
                <div className="col-12">
                    <span className="text-14 ">
                        <b>Policy Information</b>
                    </span>
                </div>
            </div>
            <div className="row smartscan-block  mx-auto ">
                <div
                    className={`form-group col-lg-4 col-md-4 col-sm-12 mb-2 ${props.policyNumberErrorMessage !="" ? "validation-div" : ""}`}
                >
                    <label>Policy Number </label>
                    <span className="text-danger "> *</span>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.policyNumber}
                        placeholder={props.fromHistoryPage == true ? "" : "Enter policy number"}
                        onChange={(e: any) => { props.onChangePolicyNumber(e.target.value) }}
                        isDisabled={props.fromHistoryPage == true ? true : false}
                    />
                    <span className="text-12 text-danger">{props.policyNumberErrorMessage}</span>
                </div>
                <div
                    className="form-group col-lg-4 col-md-4 col-sm-12 mb-2"
                >
                    <label>Policy Premium ($)</label>
                    <TextField
                        className="input-field"
                        type="text"
                        value={props.policyPremium}
                        placeholder={props.fromHistoryPage == true ? "" : "Enter policy premium"}
                        onChange={(e: any) => { props.onChangesPolicyPremium(e.target.value) }}
                        isDisabled={props.fromHistoryPage == true ? true : false}
                    />
                </div>
                <div
                    className="form-group col-lg-4 col-md-4 col-sm-12 mb-2"
                >
                    <span>Effective Date</span>
                    {props.fromHistoryPage == true ?
                        <TextField
                            className="input-field"
                            type="text"
                            value={moment(props.effectiveDate).format("MM-DD-YYYY")}
                            isDisabled={true}
                        />
                        :
                        <AtkTextField
                            className="input-field"
                            type="date"
                            id="datepicker"
                            value={moment(props.effectiveDate).format("YYYY-MM-DD")}
                            onChange={(e: any) => {
                                props.onChangeEffectiveDate(e.target.value);
                            }}
                        />
                    }
                </div>
                <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                    <span>Expiration Date</span>
                    {props.fromHistoryPage == true ?
                        <TextField
                            className="input-field"
                            type="text"
                            value={moment(props.expirationDate).format("MM-DD-YYYY")}
                            isDisabled={true}
                        />
                        :
                        <AtkTextField
                            className="input-field"
                            type="date"
                            id="datepicker"
                            value={moment(props.expirationDate).format("YYYY-MM-DD")}
                            onChange={(e: any) => {
                                props.onChangeExpiryDate(e.target.value);
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}