import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../common/Footer";
import { Toast } from "primereact/toast";
import Header from "../../common/Header";
import HomeOwnerMiddleHeader from "./HomeOwnerMiddleHeader";
import HomeOwnersSidebar from "./HomeOwnersSidebar";
import CommonValues from "../../common/utils";
import axios from "axios";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import feedbackImg from "../../assets/images/feedback.svg";
import { Dialog } from "primereact/dialog";
import { OverlayPanel } from "primereact/overlaypanel";
import AtkButton from "../../AtlaskitControls/AtkButton";
import PreviewIcon from "../../assets/images/ViewDetailsIcon.png";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import { Dropdown } from "primereact/dropdown";
import CommonSetting from "../../CommonSettings/CommonSetting";

export default function HomeOwnersHistory() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [historyloading, setHistoryLoading] = useState(true);
  const [historydata, setHistoryData] = useState<any>([]);
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const feedbackRef = useRef<OverlayPanel>(null);
  const [showPopUp, setShowPopUp] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");

  const [yearBuilt, setYearBuilt] = useState("");
  const [constructionType, setConstructionType] = useState("");
  const [Occupancy, setOccupancy] = useState("");
  const [residenceType, setResidenceType] = useState("");
  const [usageType, setUsageType] = useState("");
  const [room, setRoom] = useState("");
  const [families, setFamilies] = useState("");
  const [marketValue, setMarketValue] = useState("");
  const [replacementCost, setReplacementCost] = useState("");
  const [totalLivingArea, setTotalLivingArea] = useState("");
  const [firePlaces, setFirePlaces] = useState("");
  const [rating, setRating] = useState("");
  const [roofMaterial, setRoodMaterial] = useState("");
  const [primaryHeat, setPrimaryHeat] = useState("");
  const [foundation, setFoundation] = useState("");

  useEffect(() => {
    getHomeOwnersHistory();
  }, []);
  useEffect(() => {
    if (showPopUp) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => document.body.classList.remove("no-scroll");
  }, [showPopUp]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const getHomeOwnersHistory = () => {
    setHistoryLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/HomeOwners/homeOwnersTransactions`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setHistoryData(response.data);
        setHistoryLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting Home owners history", life: 3000 });
          }
        }
        setHistoryLoading(false);
      });
  };
  const propertyTemplate = (rowData: any) => {
    const propertyData = rowData.property ? JSON.parse(rowData.property) : {};

    return propertyData != "" && propertyData != null ? (
      <>
        <span>
          <b>Address:</b> {propertyData.Address || "N/A"}
        </span>{" "}
        &nbsp;
        <span>
          <b>City:</b> {propertyData.City || "N/A"}
        </span>
        &nbsp;
        <span>
          <b>Postal Code:</b> {propertyData.PostalCode || "N/A"}
        </span>
        &nbsp;
        <span>
          <b>Province:</b> {propertyData.Province || "N/A"}
        </span>
        &nbsp;
        <span>
          <b>Country: </b>
          {propertyData.Country || "N/A"}
        </span>
        &nbsp;
      </>
    ) : null;
  };
  const actionTemplate = (rowData: any) => {
    return rowData.homeOwnersResponse != "" ? (
      <>
        <img src={PreviewIcon} height={20} onClick={() => onPreviewClick(rowData)} title="Preview" className="show-cursor" />
      </>
    ) :
      <>
        <span className=" text-12 quote-not-span">Data Not Found</span>
      </>;

  };
  const onPreviewClick = (rowData: any) => {
    const homeOwnersResponse = rowData.homeOwnersResponse ? JSON.parse(rowData.homeOwnersResponse) : {};
      setShowPopUp(true);
      setConstructionType(homeOwnersResponse.responseJson.ConstructionType);
      setOccupancy(homeOwnersResponse.responseJson.Occupancy);
      setResidenceType(homeOwnersResponse.responseJson.ResidenceType);
      setUsageType(homeOwnersResponse.responseJson.UsageType);
      setYearBuilt(homeOwnersResponse.responseJson.YearBuild);
      setRoom(homeOwnersResponse.responseJson.Room);
      setFamilies(homeOwnersResponse.responseJson.Families);
      setMarketValue(homeOwnersResponse.responseJson.MarketValue);
      setReplacementCost(homeOwnersResponse.responseJson.ReplacementCost);
      setTotalLivingArea(homeOwnersResponse.responseJson.TotalLivingArea);
      setFirePlaces(homeOwnersResponse.responseJson.Fireplaces);
      setRating(homeOwnersResponse.responseJson.Rating);
      setRoodMaterial(homeOwnersResponse.responseJson.RoofMaterial);
      setPrimaryHeat(homeOwnersResponse.responseJson.PrimaryHeat);
      setFoundation(homeOwnersResponse.responseJson.Foundation);
  };
  const onHidePopupClicked = () => {
    setShowPopUp(false);
  };
  const feedbackTemplate = (rowData: any) => {
    return <img src={feedbackImg} className=" show-cursor" onClick={(e) => onShowFeedbackPopup(e, rowData)} title="Feedback" />;
  };
  const onShowFeedbackPopup = (e: any, rowData: any) => {
    setFeedBackError("");
    const feedback = { ...rowData, feedback: "" };
    setFeedBackData(feedback);
    feedbackRef.current?.toggle(e);
  };
  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };
  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };
  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    setHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.id === rowData.id ? { ...response, isLiked: liked } : response))
    );
    onSaveRatings(rowData);
  };
  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
    setHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.id === rowData.id ? { ...response, isLiked: liked } : response))
    );
  };
  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      onSaveFeedback(rowData);
    }
  };
  const onSaveRatings = (rowData: any) => {
    const token = CommonValues.GetToken();
    let data = {
      Id: rowData.id,
      IsLiked: rowData.isLiked,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/HomeOwners/homeOwnersRatings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Rating submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };
  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      Id: rowData.id,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/HomeOwners/homeOwnersFeedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Feedback submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };
  return (
    <>
      <div className="homeowner-main-page">
        <Toast ref={toast} />
        <Header pagename="homeowner-history" />
        <div className="content-wrapper">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <HomeOwnerMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto ">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <HomeOwnersSidebar pagename="homeowner-history" />
              </div>
              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row   d-flex  align-items-end text-12 position-relative">
                  <div className="col-12  history-table mt-3">
                    <Toast ref={toast} />
                    <div className="mb-3 mt-1">
                          <span className="p-input-icon-left w-25">
                            <i className="fa fa-search grid-search-icon" />
                            <AtkTextField
                              type="text"
                              id="txtfirstName"
                              name="globalFilter"
                              onChange={(e) => setGlobalFilter(e.target.value)}
                              value={globalFilter}
                              className="input-field"
                              placeholder="Search..."
                            />
                          </span>
                        </div>
                    {historyloading ? (
                      <>
                      <div className="d-flex align-ietms-center justify-content-center overflow-hidden">
                        <AtkLoader />
                      </div>
                      </>
                    ) : (
                        <DataTable
                          value={historydata}
                          paginator
                          paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                          rows={10}
                          globalFilter={globalFilter}
                          emptyMessage="No data available."
                        >
                          <Column header="Insured Name" field="insuredName" sortable
                          ></Column>
                          <Column
                            header="Submitted Date"
                            field="created"
                            body={(rowData) => {
                              const utcdate = moment.utc(rowData.created);
                              var localDate = moment(utcdate).local();
                              return <span className="">{localDate.format("MM-DD-YYYY HH:mm")} </span>;
                            }}
                            sortable
                          ></Column>
                          <Column header="Property" body={propertyTemplate} field="property" sortable 
                          ></Column>
                          <Column header="Action" body={actionTemplate}></Column>
                          <Column header="Feedback" body={feedbackTemplate}></Column>
                        </DataTable>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
        <div className="row mx-auto d-flex align-items-center mb-2">
          <div className="col-12 feedback-header">
            <h4>Feedback</h4>
          </div>
          <div className={"form-group col-12 mb-2 text-12"}>
            <textarea
              name="feedback"
              onChange={(e) => onFeedbackChange(e.target.value)}
              value={feedBackData.feedback}
              className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
              rows={4}
              placeholder="Enter your feedback here"
            />
            <span className="text-danger  text-12 mt-1">{feedBackError}</span>
          </div>
          <div className="col-4 d-flex justify-content-evenly">
            <i
              className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
                     ${feedBackData.isLiked == true ? " active" : " "}`}
              aria-hidden="true"
              onClick={(e) => onThumbsUpClick(feedBackData)}
            ></i>
            <i
              className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                      ${feedBackData.isLiked == false ? " active" : " "}`}
              aria-hidden="true"
              onClick={(e) => onThumbDownClick(feedBackData)}
            ></i>
          </div>
          <div className="col-4 text-end p-2 pe-0  ">
            <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
          </div>
          <div className="col-4 text-end p-2  ">
            {/* {submissionId == feedBackData.submissionId && feedbackLoading ? ( */}
            {feedbackLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <AtkLoader />
              </div>
            ) : (
              <AtkButton label="Submit" onClick={() => onSubmitFeedbackClick(feedBackData)} className="pf-secondary-btn w-auto " />
            )}
          </div>
        </div>
      </OverlayPanel>
      <Dialog header="Home Owners" visible={showPopUp} style={{ width: "70vw" }} onHide={onHidePopupClicked} draggable={false}>
        <div className="row mt-3 mx-auto text-12">
          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Construction Type</label>
            <AtkTextField className="input-field" type="text" id="text" value={constructionType} isDisabled={true} />
          </div>
          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Occupancy</label>
            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={Occupancy}
              isDisabled={true}
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Residence Type</label>
            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={residenceType}
              isDisabled={true}
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Usage Type</label>
            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={usageType}
              isDisabled={true}
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Year Build</label>
            <AtkTextField className="input-field" type="text" id="text" value={yearBuilt} isDisabled={true} />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Room</label>
            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={room}
              isDisabled={true}
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Families</label>
            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={families}
              isDisabled={true}
            />
          </div>
          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Market Value</label>
            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={marketValue}
              isDisabled={true}
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Replacement Cost</label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={replacementCost}
              isDisabled={true}
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Total Living Area</label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={totalLivingArea}
              isDisabled={true}
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Fire Places</label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={firePlaces}
              isDisabled={true}
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Rating </label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={rating}
              isDisabled={true}
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Roof Material</label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={roofMaterial}
              isDisabled={true}
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Primary Heat</label>
            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={primaryHeat}
              isDisabled={true}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}
